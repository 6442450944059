
@-webkit-keyframes tp-pulse {
	0% {
	  -webkit-box-shadow: -100% 0 0 0 rgba(255,255,255, 0.4);
	}
	70% {
		-webkit-box-shadow: -50% 0 0 45px rgba(255,255,255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}
@keyframes tp-pulse {
0% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
}
70% {
	-moz-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	box-shadow: 0 0 0 45px rgba(255,255,255, 0);
}
100% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}

@keyframes abanimation {
    0% {
    transform: translateX(0px);
    }
    25% {
        transform: translateX(-25px);
    }
    50% {
        transform: translateX(0px);
    }
    75% {
        transform: translateX(25px);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes ab2animation {
    0% {
    transform: translateY(0px);
    }
    25% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(0px);
    }
    75% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes ab3animation {
    0% {
    transform: translateX(0px);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(0px);
    }
    75% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0px);
    }
}