@charset "UTF-8";
@font-face {
  font-family: "Gordita-300";
  src: url("../fonts/Gordita-Light.woff2") format("woff2"), url("../fonts/Gordita-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gordita-400";
  src: url("../fonts/Gordita-Regular.woff2") format("woff2"), url("../fonts/Gordita-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gordita-500";
  src: url("../fonts/Gordita-Medium.woff2") format("woff2"), url("../fonts/Gordita-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gordita-700";
  src: url("../fonts/Gordita-Bold.woff2") format("woff2"), url("../fonts/Gordita-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
:root {
  /**
  @font family declaration
  */
  --tp-ff-body: Gordita-400;
  --tp-ff-heading: Gordita-700;
  --tp-ff-p: Gordita-400;
  --tp-ff-fontawesome: "Font Awesome 6 Pro";
  /**
  @color declaration
  */
  --tp-common-white: #ffffff;
  --tp-common-black: #000;
  --tp-heading-primary: #050D36;
  --tp-heading-secondary: #FF6652;
  --tp-heading-b-light: #050D36;
  --tp-grey-1: #F8FAFA;
  --tp-grey-2: #F5F6F8;
  --tp-text-body: #000;
  --tp-text-1: #000;
  --tp-text-2: #777C90;
  --tp-theme-1: #FF6652;
  --tp-theme-2: #F7F8F9;
  --tp-border-1: #fff;
  --tp-border-2: #245D51;
  /**
  @font weight declaration
  */
  --tp-fw-normal: normal;
  --tp-fw-elight: 200;
  --tp-fw-light: 300;
  --tp-fw-regular: 400;
  --tp-fw-medium: 500;
  --tp-fw-sbold: 600;
  --tp-fw-bold: 700;
  /**
  @font size declaration
  */
  --tp-fz-body: 14px;
  --tp-fz-p: 16px;
  --tp-fz-h1: 40px;
  --tp-fz-h2: 36px;
  --tp-fz-h3: 24px;
  --tp-fz-h4: 20px;
  --tp-fz-h5: 16px;
  --tp-fz-h6: 14px;
}

/*-----------------------------------------------------------------------------------

    Theme Name: Template Name – HTML5 Template
    Author: ThemePure
    Support: basictheme400@gmail.com
    Description: Template Name  – HTML5 Template
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

    01. THEME DEFAULT CSS
    02. HEADER CSS
	03. BANNAR
	04. SLIDER
	05. FEATURE
	06. ABOUT
	07. CATEGORY
	08. COURSE
	09. CHOOSE
	10. COUNTER
	11. INSTRUCTOR
	12. SUITABLE
	13. TESTIMONIAL
	14. BRAND
	15. MEANMENU
	16. BLOG
	17. CONTACT
	18. FAQ
	19. CART
	20. LOG-IN
	21. FOOTER

**********************************************/
/*----------------------------------------*/
/*  01. THEME DEFAULT CSS START
/*----------------------------------------*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*---------------------------------
    typography css start 
---------------------------------*/
body {
  font-family: var(--tp-ff-body);
  font-size: var(--tp-fz-body);
  font-weight: normal;
  color: var(--tp-text-body);
  line-height: 26px;
  overflow-X: hidden;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tp-ff-heading);
  color: var(--tp-heading-primary);
  margin-top: 0px;
  font-weight: var(--tp-fw-bold);
  line-height: 1.2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

h1 {
  font-size: var(--tp-fz-h1);
}

h2 {
  font-size: var(--tp-fz-h2);
}

h3 {
  font-size: var(--tp-fz-h3);
}

h4 {
  font-size: var(--tp-fz-h4);
}

h5 {
  font-size: var(--tp-fz-h5);
}

h6 {
  font-size: var(--tp-fz-h6);
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

p {
  font-family: var(--tp-ff-p);
  font-size: var(--tp-fz-p);
  font-weight: var(--tp-fw-normal);
  color: var(--tp-text-2);
  margin-bottom: 15px;
  line-height: 28px;
}

a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
  border: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input {
  outline: none;
}

input[type=color] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

::selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

*::placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/
img {
  max-width: 100%;
  height: auto;
}

.w-img img {
  width: 100%;
}

.m-img img {
  max-width: 100%;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.z-index-1 {
  z-index: 1;
}

.z-index-11 {
  z-index: 11;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-bottom {
  background-position: bottom;
  background-repeat: no-repeat;
}

.f-wrap {
  flex-wrap: wrap;
}

/*----------------------------------------
    Body Overlay 
-----------------------------------------*/
.body-overlay {
  background-color: rgba(var(--tp-common-black), 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.body-overlay:hover {
  cursor: pointer;
}

.body-overlay.opened {
  opacity: 1;
  visibility: visible;
}

/*----------------------------------------
    Progress Wrap
-----------------------------------------*/
@media (max-width: 767px) {
  .progress-wrap {
    right: 15px;
    bottom: 15px;
  }
}

.basic-pagination ul li {
  display: inline-block;
}
.basic-pagination ul li:not(:last-child) {
  margin-right: 15px;
}
.basic-pagination ul li a, .basic-pagination ul li span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  border: 2px solid #f1f1f1;
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-text-2);
}
.basic-pagination ul li a:hover, .basic-pagination ul li a.current, .basic-pagination ul li span:hover, .basic-pagination ul li span.current {
  background: var(--tp-border-2);
  border-color: var(--tp-border-2);
  color: var(--tp-common-white);
}

/*--
    - Background color
-----------------------------------------*/
.grey-bg {
  background-color: var(--tp-grey-1);
}

.grey-bg-2 {
  background-color: var(--tp-grey-2);
}

.white-bg {
  background-color: var(--tp-common-white);
}

.black-bg {
  background-color: var(--tp-common-black);
}

.theme-bg {
  background-color: var(--tp-border-2);
}

.theme-bg-secondary {
  background-color: var(--tp-theme-2);
}

/* error-area */
.error-title {
  color: #050D36;
  font-size: 44px;
  line-height: 1.4;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-title {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .error-title {
    font-size: 30px;
  }
}

/*--
    - Spacing
-----------------------------------------*/
/* pulse btn */
.pulse-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background-color: var(--tp-common-white);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: var(--tp-common-black);
  animation: pulse 2s infinite;
}
.pulse-btn:hover {
  background-color: var(--tp-common-black);
  color: var(--tp-common-black);
}
.pulse-btn i {
  padding-left: 2px;
}

/* hambur btn */
.hamurger-btn {
  width: 30px;
  height: 30px;
  position: relative;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;
}
.hamurger-btn span {
  display: inline-block;
  width: 100%;
  background: var(--tp-common-black);
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  opacity: 1;
  left: 0;
  z-index: 1;
}
.hamurger-btn span:nth-child(1) {
  top: 0;
}
.hamurger-btn span:nth-child(2) {
  top: 10px;
}
.hamurger-btn span:nth-child(3) {
  top: 20px;
}

/* theme btn */
.tp-btn {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: var(--tp-common-white);
  background: var(--tp-theme-1);
  line-height: 1;
  text-align: center;
  padding: 21px 32px;
  text-transform: capitalize;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.tp-btn i {
  padding-left: 5px;
}
.tp-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-black);
}
.tp-btn:focus {
  color: var(--tp-common-white);
}

.tp-s-border-btn {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: var(--tp-common-white);
  background: transparent;
  border: 1px solid #fff;
  line-height: 1;
  text-align: center;
  padding: 20px 32px;
  text-transform: capitalize;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.tp-s-border-btn i {
  padding-left: 5px;
}
.tp-s-border-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-heading-secondary);
  border: 1px solid var(--tp-heading-secondary);
}
.tp-s-border-btn:focus {
  color: var(--tp-common-white);
}

/* border btn */
.tp-border-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: var(--tp-heading-secondary);
  background: var(--tp-common-white);
  border: 1px solid var(--tp-heading-secondary);
  line-height: 28px;
  text-align: center;
  padding: 10px 25px;
  text-transform: capitalize;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.tp-border-btn i {
  padding-left: 5px;
}
.tp-border-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-heading-secondary);
}
.tp-border-btn:focus {
  color: var(--tp-common-white);
}

/* border btn */
.tp-border-btn2 {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: var(--tp-common-white);
  background-color: var(--tp-heading-secondary);
  border: 1px solid var(--tp-heading-secondary);
  line-height: 28px;
  text-align: center;
  padding: 7px 20px;
  text-transform: capitalize;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.tp-border-btn2 i {
  padding-left: 5px;
}
.tp-border-btn2:hover {
  color: var(--tp-heading-secondary);
  background-color: var(--tp-common-white);
}
.tp-border-btn2:focus {
  color: var(--tp-common-white);
}

/* link btn style 1 */
.link-btn {
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: var(--tp-text-3);
  background: transparent;
  border: 1px solid var(--tp-border-2);
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 48px;
  padding-right: 25px;
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  overflow: hidden;
}
.link-btn i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
}
.link-btn i:last-child {
  left: 0%;
  visibility: hidden;
  opacity: 0;
}
.link-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-1);
  border-color: var(--tp-theme-1);
}
.link-btn:hover i {
  left: 100%;
  visibility: hidden;
  opacity: 0;
}
.link-btn:hover i:last-child {
  left: 50%;
  visibility: visible;
  opacity: 1;
}

/* link btn style 2 */
.link-btn-2 {
  position: relative;
  font-size: 16px;
  color: var(--tp-text-1);
  font-weight: 500;
  padding-right: 21px;
  display: inline-block;
}
.link-btn-2 i {
  font-size: 14px;
  position: absolute;
  top: 12px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
}
.link-btn-2 i:first-child {
  right: 10%;
  visibility: hidden;
  opacity: 0;
}
.link-btn-2 i:last-child {
  right: 0;
}
.link-btn-2:hover {
  color: var(--tp-theme-1);
}
.link-btn-2:hover i:first-child {
  right: 0;
  visibility: visible;
  opacity: 1;
}
.link-btn-2:hover i:last-child {
  right: -10%;
  visibility: hidden;
  opacity: 0;
}
.link-btn-2.link-prev {
  padding-right: 0;
  padding-left: 21px;
}
.link-btn-2.link-prev i:first-child {
  left: 10%;
  right: auto;
}
.link-btn-2.link-prev i:last-child {
  left: 0;
  right: auto;
}
.link-btn-2.link-prev:hover i:first-child {
  left: 0%;
  right: auto;
}
.link-btn-2.link-prev:hover i:last-child {
  left: -10%;
  right: auto;
}

.section-title p {
  margin-bottom: 0;
  line-height: 28px;
}

.tp-sub-title {
  color: var(--tp-heading-secondary);
  font-size: 20px;
  display: block;
  font-family: "Gordita-500";
}

.tp-section-title {
  font-size: 25px;
  line-height: 1.3;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-section-title {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-section-title {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .tp-section-title {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-section-title br {
    display: none;
  }
}

.tp-sub-title-box {
  padding: 12px 20px;
  font-size: 16px;
  background: rgba(255, 102, 82, 0.1);
  color: var(--tp-heading-secondary);
  border-radius: 5px;
  display: inline-block;
  font-family: "Gordita-500";
  line-height: 1;
}

.hero-title-black {
  color: var(--tp-heading-b-light);
  font-size: 68px;
  line-height: 1.3;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-title-black {
    font-size: 64px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-title-black {
    font-size: 54px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-title-black {
    font-size: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), lg, only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-title-black br {
    display: none;
  }
}

.tp-bline-stitle {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-family: "Gordita-500";
  font-size: 18px;
  color: var(--tp-heading-secondary);
  line-height: 1;
}
.tp-bline-stitle::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: var(--tp-heading-secondary);
}

/*----------------------------------------*/
/*  24. BREADCRUMB CSS START
/*----------------------------------------*/
.breadcrumb__title {
  font-size: 48px;
  color: var(--tp-common-white);
}
@media (max-width: 767px) {
  .breadcrumb__title {
    font-size: 38px;
  }
}
.breadcrumb__title-2 {
  font-size: 50px;
  line-height: 1.1;
  margin-top: 12px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb__title-2 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .breadcrumb__title-2 {
    font-size: 26px;
  }
}
.breadcrumb__title-pre {
  display: inline-block;
  height: 24px;
  line-height: 26px;
  font-size: 14px;
  color: var(--tp-common-white);
  font-weight: 500;
  background: var(--tp-theme-2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 0 7px;
  margin-bottom: 12px;
}
.breadcrumb__list {
  display: inline-block;
  background-color: var(--tp-common-white);
  padding: 10px 24px;
}
@media (max-width: 767px) {
  .breadcrumb__list {
    padding: 8px 14px;
  }
}
.breadcrumb__list span {
  font-size: 18px;
  color: var(--tp-text-2);
  font-weight: 500;
  padding-right: 3px;
  margin-right: 3px;
}
@media (max-width: 767px) {
  .breadcrumb__list span {
    font-size: 14px;
  }
}
.breadcrumb__list span a {
  transition: 0.3s;
  display: inline-block;
}
.breadcrumb__list span a:hover {
  color: var(--tp-common-black);
}
.breadcrumb__list span.sub-page-black {
  color: var(--tp-common-black);
}
.breadcrumb__list-2 span {
  font-size: 14px;
  color: var(--tp-text-11);
  font-weight: 500;
  padding-right: 3px;
  margin-right: 3px;
  text-transform: capitalize;
}
.breadcrumb__list-2 span a:hover {
  color: var(--tp-theme-1);
}
.breadcrumb__overlay {
  position: relative;
}
.breadcrumb__overlay::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(5, 13, 54, 0.29));
}

@-webkit-keyframes tp-pulse {
  0% {
    -webkit-box-shadow: -100% 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: -50% 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes tp-pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes abanimation {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-25px);
  }
  50% {
    transform: translateX(0px);
  }
  75% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes ab2animation {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes ab3animation {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(0px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}
#loading {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  margin-top: 0px;
  top: 0px;
  display: none;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 25%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  #loading-center-absolute {
    width: 40%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #loading-center-absolute {
    width: 40%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #loading-center-absolute {
    width: 45%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  #loading-center-absolute {
    width: 50%;
  }
}
@media (max-width: 575px) {
  #loading-center-absolute {
    width: 60%;
  }
}
#loading {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  margin-top: 0px;
  top: 0px;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.object {
  width: 20px;
  height: 20px;
  background-color: #5f3afc;
  -moz-border-radius: 50% 50% 50% 50%;
  -webkit-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
  margin-right: 20px;
  margin-bottom: 20px;
  position: absolute;
}

#object_one {
  -webkit-animation: object 2s linear infinite;
  animation: object 2s linear infinite;
}

#object_two {
  -webkit-animation: object 2s linear infinite -0.4s;
  animation: object 2s linear infinite -0.4s;
}

#object_three {
  -webkit-animation: object 2s linear infinite -0.8s;
  animation: object 2s linear infinite -0.8s;
}

#object_four {
  -webkit-animation: object 2s linear infinite -1.2s;
  animation: object 2s linear infinite -1.2s;
}

#object_five {
  -webkit-animation: object 2s linear infinite -1.6s;
  animation: object 2s linear infinite -1.6s;
}

@-webkit-keyframes object {
  0% {
    left: 100px;
    top: 0;
  }
  80% {
    left: 0;
    top: 0;
  }
  85% {
    left: 0;
    top: -20px;
    width: 20px;
    height: 20px;
  }
  90% {
    width: 40px;
    height: 15px;
  }
  95% {
    left: 100px;
    top: -20px;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 100px;
    top: 0;
  }
}
@keyframes object {
  0% {
    left: 100px;
    top: 0;
  }
  80% {
    left: 0;
    top: 0;
  }
  85% {
    left: 0;
    top: -20px;
    width: 20px;
    height: 20px;
  }
  90% {
    width: 40px;
    height: 15px;
  }
  95% {
    left: 100px;
    top: -20px;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 100px;
    top: 0;
  }
}
.loading-icon .loading-logo {
  width: 64px;
  height: 64px;
  -webkit-animation: ghurche 4s linear infinite -1.2s;
  animation: ghurche 4s linear infinite -1.2s;
}

@media (max-width: 575px) {
  .loading-icon .loading-logo {
    margin-bottom: 10px;
  }
}
@-webkit-keyframes ghurche {
  0% {
    left: 100px;
    top: 0;
  }
  100% {
    left: 100px;
    top: 0;
  }
}
@keyframes ghurche {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*----------------------------------------*/
/*  02. HEADER CSS START
/*----------------------------------------*/
.header__transparent {
  position: absolute;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  z-index: 99;
  background: transparent;
}
.header__info ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}
.header__info ul li:last-child {
  margin-right: 0;
}
.header__info ul li a {
  color: var(--tp-text-2);
  font-size: 13px;
}
.header__info ul li a:hover {
  color: var(--tp-theme-1);
}
.header__info ul li a i {
  margin-right: 5px;
}
.header__info ul li a svg {
  width: 13px;
  height: 13px;
  margin-right: 5px;
}
.header__info ul li a svg path {
  fill: var(--tp-text-4);
}
.header__border {
  border-bottom: 1px solid rgba(3, 18, 32, 0.07);
}
.header__search-input {
  position: relative;
}
.header__search-input input {
  width: 200px;
  height: 50px;
  background-color: var(--tp-grey-1);
  border: 1px solid var(--tp-grey-1);
  padding: 0 20px;
  padding-left: 40px;
  border-radius: 50px;
}
.header__search-input input::-webkit-input-placeholder {
  color: var(--tp-text-3);
}
.header__search-input input:-moz-placeholder {
  color: var(--tp-text-3);
}
.header__search-input input::-moz-placeholder {
  color: var(--tp-text-3);
}
.header__search-input input:-ms-input-placeholder {
  color: var(--tp-text-3);
}
.header__search-input input::placeholder {
  font-size: 16px;
  color: var(--tp-text-2);
}
.header__search-btn {
  position: absolute;
  top: 46%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15px;
  font-size: 20px;
  color: var(--tp-heading-secondary);
  background-color: var(--tp-grey-1);
}
.header__search-2 {
  margin-left: 13px;
}
.header__search-2 input {
  height: 44px;
  line-height: 44px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  background-color: var(--tp-grey-5);
}
.header__search-2 input::-webkit-input-placeholder {
  color: var(--tp-text-10);
}
.header__search-2 input:-moz-placeholder {
  color: var(--tp-text-10);
}
.header__search-2 input::-moz-placeholder {
  color: var(--tp-text-10);
}
.header__search-2 input:-ms-input-placeholder {
  color: var(--tp-text-10);
}
.header__search-2 button::after {
  background-color: rgba(62, 132, 84, 0.14);
}
.header__search-3 button svg path {
  stroke: var(--tp-theme-3);
}
.header__hamburger {
  margin-top: 3px;
}
.header__action ul li {
  list-style: none;
}
.header__action ul li a {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 38px;
  text-align: center;
  border: 2px solid rgba(12, 20, 15, 0.08);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.header__action ul li a:hover {
  border-color: var(--tp-common-black-3);
}
.header__sticky.header__sticky {
  position: fixed !important;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  z-index: 99;
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  background: var(--tp-common-white);
}
.header__lang .nice-select {
  padding: 0;
  padding-right: 17px;
  font-size: 15px;
  border: 0;
  color: var(--tp-text-11);
}
.header__lang .nice-select .current {
  color: var(--tp-text-11);
  font-size: 15px;
}
.header__lang .nice-select::after {
  right: 0;
  border-width: 1.5px;
  border-color: var(--tp-text-11);
  height: 6px;
  width: 6px;
  margin-top: -6px;
}
.header__lang .nice-select .list {
  border-radius: 0;
  margin-top: 0;
  left: auto;
  right: 0;
}
.header__lang .nice-select .list .option:hover, .header__lang .nice-select .list .option.selected.focus {
  color: var(--tp-theme-3);
}
.header__social ul li {
  display: inline-block;
  margin-right: 5px;
}
.header__social ul li:last-child {
  margin-right: 0;
}
.header__social ul li a {
  font-size: 14px;
  color: var(--tp-common-black);
  display: inline-block;
}
.header__social ul li a:hover {
  color: var(--tp-theme-1);
}

/* logo */
.logo img {
  width: 100%;
  max-width: 200px;
}

.header-xy-spacing {
  padding: 30px 220px;
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .header-xy-spacing {
    padding-left: 90px;
    padding-right: 90px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .header-xy-spacing {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 767px) {
  .header-xy-spacing {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-cat-menu {
    margin-left: 15px;
  }
}
.header-cat-menu ul li {
  position: relative;
  display: block;
  margin: 0;
}
.header-cat-menu ul li a {
  display: block;
  padding: 12px 28px;
  font-size: 16px;
  color: var(--tp-common-white);
  background: var(--tp-heading-secondary);
  border-radius: 5px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-cat-menu ul li a {
    padding: 8px 8px;
  }
}
.header-cat-menu ul li .sub-menu {
  position: absolute;
  top: 120%;
  left: 0;
  width: 200px;
  background: var(--tp-common-white);
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  -moz-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  -ms-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  -o-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  padding: 15px 0;
  border-radius: 4px;
}
.header-cat-menu ul li .sub-menu > li > a {
  background: var(--tp-common-white);
  color: var(--tp-text-2);
  display: block;
  padding: 3px 30px;
  width: 100%;
  font-size: 15px;
}
.header-cat-menu ul li .sub-menu > li > a:hover {
  color: var(--tp-heading-secondary);
}
.header-cat-menu ul li:hover .sub-menu {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

/* main menu css */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu {
    margin-left: 50px;
  }
}
.main-menu ul > li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-right: 27px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul > li {
    margin-right: 20px;
  }
}
.main-menu ul > li > a {
  position: relative;
  display: inline-block;
  font-family: "Gordita-500";
  font-size: 16px;
  color: var(--tp-common-white);
  padding: 23px 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul > li > a {
    font-size: 15px;
  }
}
.main-menu ul > li > a::before {
  position: absolute;
  content: "";
  background-image: url("../../assets/img/banner/h-shape-01.png");
  top: 53px;
  left: 0;
  height: 4px;
  width: 0px;
}
.main-menu ul > li.has-dropdown > a {
  position: relative;
}
.main-menu ul > li.has-dropdown > a::after {
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  -ms-transform: translateY(1px);
  -o-transform: translateY(1px);
  transform: translateY(1px);
  font-size: 16px;
  color: var(--tp-common-white);
  font-family: var(--tp-ff-fontawesome);
  font-weight: medium;
  margin-left: 5px;
  display: inline-block;
}
.main-menu ul > li .submenu {
  position: absolute;
  top: 120%;
  left: 0;
  width: 200px;
  background: var(--tp-common-white);
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  -moz-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  -ms-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  -o-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
}
.main-menu ul > li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
}
.main-menu ul > li .submenu li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.main-menu ul > li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 25px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.main-menu ul > li .submenu li a {
  padding: 10px 25px;
  font-size: 14px;
  z-index: 1;
  color: var(--tp-common-black);
  width: 100%;
}
.main-menu ul > li .submenu li a::before {
  display: none;
}
.main-menu ul > li .submenu li .submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.main-menu ul > li .submenu li:hover > a {
  color: var(--tp-heading-secondary);
}
.main-menu ul > li .submenu li:hover > .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
.main-menu ul > li:hover > a {
  color: var(--tp-theme-1);
}
.main-menu ul > li:hover > a::after {
  color: var(--tp-theme-1);
}
.main-menu ul > li:hover > a::before {
  width: 41px;
}
.main-menu ul > li:hover > .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

.main-menu-white ul li {
  margin-right: 55px;
}
.main-menu-white ul li a {
  padding: 35px 0;
  font-family: "Gordita-500";
  color: var(--tp-text-2);
}
.main-menu-white ul li a:hover {
  color: var(--tp-common-black);
}
.main-menu-white ul li a::before {
  top: 68px;
}
.main-menu-white ul li.has-dropdown a::after {
  color: var(--tp-text-2);
}

.header-meta {
  margin-left: 4px;
}
@media (max-width: 767px) {
  .header-meta ul {
    text-align: right;
  }
}
.header-meta ul li {
  list-style: none;
  display: inline-block;
  margin-left: 18px;
}
.header-meta ul li > a {
  font-size: 20px;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 53px;
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
  border-radius: 50px;
  color: var(--tp-common-white);
}
.header-meta ul li > a:hover {
  background: var(--tp-heading-secondary);
}

.header-meta-green ul {
  display: flex;
  justify-content: center;
}
.header-meta-green ul li a {
  font-size: 20px;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 53px;
  text-align: center;
  border-radius: 50px;
  color: #245D51;
  background-color: rgba(36, 93, 81, 0.1);
  margin-left: 20px;
}
@media (max-width: 767px) {
  .header-meta-green ul li a {
    margin-left: 10px;
    height: 35px;
    width: 35px;
    line-height: 35px;
    font-size: 16px;
  }
}
.header-meta-green ul li a:hover {
  background-color: var(--tp-border-2);
  color: var(--tp-common-white);
}

.header-meta-white ul li a {
  color: var(--tp-border-2);
  background: rgba(36, 93, 81, 0.1);
}
.header-meta-white ul li a:hover {
  color: var(--tp-common-white);
}

div.main-menu-black ul li {
  margin-right: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  div.main-menu-black ul li {
    margin-right: 25px;
  }
}
div.main-menu-black ul li a {
  color: var(--tp-text-2);
}
div.main-menu-black ul li a:hover {
  color: var(--tp-common-black);
}
div.main-menu-black ul li.has-dropdown > a::after {
  color: var(--tp-text-2);
}
div.main-menu-black ul li.has-dropdown > a:hover::after {
  color: var(--tp-common-black);
}

.header-search-box form {
  position: relative;
}
.header-search-box form input {
  width: 100%;
  height: 60px;
  padding: 0 200px 0 25px;
  font-size: 16px;
  color: var(--tp-text-2);
  border: none;
  background-color: #F4F7F6;
  border-radius: 5px;
}
.header-search-box form input::placeholder {
  font-size: 16px;
  color: var(--tp-text-2);
}
.header-search-box .header-search-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 12px 25px;
  font-size: 16px;
  color: #fff;
  background-color: var(--tp-border-2);
  border-radius: 5px;
}

.tp-sidebar-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 320px;
  height: 100%;
  background: #ffffff none repeat scroll 0 0;
  box-shadow: rgba(5, 13, 54, 0.05) 5px 15px 30px 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 99;
  padding: 30px;
  transform: translateX(100%);
  overflow-y: scroll;
}

.mobile-header-area {
  padding: 20px 0;
}

.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
}

.body-overlay.opened {
  opacity: 1;
  visibility: visible;
}

.tp-sidebar-menu.sidebar-opened {
  transform: translateX(0%);
}

.sidebar-close {
  position: absolute;
  right: 30px;
  z-index: 222;
  font-size: 35px;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.sidebar-info {
  clear: both;
  margin-top: 40px;
  overflow: hidden;
}

.mobile-menu.mean-container {
  overflow: hidden;
}

.side_circle li {
  position: relative;
  padding-left: 15px;
  font-size: 15px;
  margin-bottom: 7px;
}
.side_circle li::before {
  top: 0.8em;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid #666;
  background-color: transparent;
  content: "";
  display: inline-block;
  position: absolute;
}

ul.bili_circle li::before {
  top: 0.8em;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid var(--bili-list-icon-color);
  background-color: transparent;
}

.side-social {
  margin-top: 30px;
}
.side-social a {
  margin-right: 10px;
}

.header-sticky {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  z-index: 99;
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  background: var(--tp-border-2);
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-menu-area.header-sticky {
  background-color: var(--tp-common-white);
  padding-top: 0;
  padding-bottom: 0;
}

.mob-white-sticky.header-sticky {
  background-color: var(--tp-common-white);
  padding-top: 10px;
  padding-bottom: 10px;
}

.third-header.header-sticky {
  background-color: var(--tp-common-white);
}

/*----------------------------------------*/
/*  03. BANNAR CSS START
/*----------------------------------------*/
.banner-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 1005px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg {
    height: 768px;
  }
}
@media (max-width: 767px) {
  .banner-bg {
    height: 670px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-bg-rainbow {
    height: 768px;
  }
}
@media (max-width: 767px) {
  .banner-bg-rainbow {
    height: 600px;
  }
}

.hero-content {
  padding-top: 350px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 767px) {
  .hero-content {
    padding-top: 290px;
  }
}
@media (max-width: 767px) {
  .hero-content {
    padding-top: 190px;
  }
}
.hero-content span {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 20px;
  color: var(--tp-common-white);
  display: block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .hero-content span {
    font-size: 18px;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .hero-content span {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
.hero-content .hero-title {
  font-size: 40px;
  line-height: 1.3;
  color: var(--tp-common-white);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-content .hero-title {
    font-size: 54px;
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content .hero-title {
    font-size: 47px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .hero-content .hero-title {
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
}
.hero-content .hero-title i {
  color: var(--tp-heading-secondary);
  font-style: normal;
}
.hero-content p {
  font-size: 25px;
  color: var(--tp-common-white);
  line-height: 1.7;
  margin-bottom: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-content p {
    font-size: 16px;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content p {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .hero-content p {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .hero-content p br {
    display: none;
  }
}

.banner-info ul li {
  position: absolute;
  font-size: 16px;
  padding: 25px 21px;
  border: 1px solid var(--tp-common-white);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: block;
  z-index: 2;
  backdrop-filter: blur(100px);
  color: var(--tp-common-white);
}
.banner-info ul li span {
  margin-bottom: 5px;
  display: block;
  font-size: 28px;
  font-weight: var(--tp-fw-bold);
}
.banner-info ul li:nth-child(1) {
  right: 695px;
  top: 340px;
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .banner-info ul li:nth-child(1) {
    right: 560px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .banner-info ul li:nth-child(1) {
    right: 400px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-info ul li:nth-child(1) {
    right: 350px;
    top: 380px;
  }
}
.banner-info ul li:nth-child(1) span {
  color: var(--tp-heading-secondary);
}
.banner-info ul li:nth-child(2) {
  top: 655px;
  right: 530px;
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .banner-info ul li:nth-child(2) {
    right: 365px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .banner-info ul li:nth-child(2) {
    right: 200px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-info ul li:nth-child(2) {
    top: 500px;
    right: 20px;
  }
}
.banner-info ul li:nth-child(2) span {
  color: #9B51E0;
}
.banner-info ul li:nth-child(3) {
  top: 460px;
  right: 200px;
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .banner-info ul li:nth-child(3) {
    right: 35px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .banner-info ul li:nth-child(3) {
    top: 200px;
    right: 55px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .banner-info ul li:nth-child(3) {
    top: 220px;
    right: 250px;
  }
}
.banner-info ul li:nth-child(3) span {
  color: #F2C94C;
}

.b-shape {
  position: absolute;
  bottom: 0;
  right: 214px;
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .b-shape {
    right: 50px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .b-shape {
    right: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .b-shape {
    right: -80px;
    width: 650px;
    height: auto;
  }
}

.b-shape-3 {
  position: absolute;
  bottom: 132px;
  right: 215px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .b-shape-3 {
    right: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .b-shape-3 {
    right: 0;
    width: 500px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .b-shape-3 {
    right: 0;
    width: 500px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .b-shape-3 {
    bottom: 175px;
    right: 0;
    width: 350px;
  }
}

@media (max-width: 767px) {
  .hero-title-black {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .hero-title-black br {
    display: none;
  }
}

@media (max-width: 767px) {
  .hero-content-black {
    padding-top: 220px;
  }
}

/*----------------------------------------*/
/*  01. SLIDER CSS START
/*----------------------------------------*/
.slider-bg-height {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 850px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-bg-height {
    min-height: 767px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-bg-height {
    min-height: 600px;
  }
}
@media (max-width: 767px) {
  .slider-bg-height {
    min-height: 560px;
  }
}

.slider-arrow .slick-arrow {
  position: absolute;
  top: 50%;
  left: 120px;
  transform: translateY(-50%);
  height: 70px;
  width: 70px;
  text-align: center;
  display: block;
  line-height: 70px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.3);
  color: var(--tp-common-white);
  z-index: 2;
}
.slider-arrow .slick-arrow:hover {
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .slider-arrow .slick-arrow {
    left: 40px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-arrow .slick-arrow {
    top: 82%;
    left: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-arrow .slick-arrow {
    top: 90%;
    left: 40px;
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .slider-arrow .slick-arrow {
    height: 50px;
    width: 50px;
    line-height: 50px;
    top: 90%;
    left: 20px;
  }
}
.slider-arrow .slick-next {
  left: auto;
  right: 120px;
}
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .slider-arrow .slick-next {
    right: 40px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-arrow .slick-next {
    left: 130px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-arrow .slick-next {
    left: 110px;
  }
}
@media (max-width: 767px) {
  .slider-arrow .slick-next {
    left: 90px;
  }
}

.slider-text {
  font-family: "Gordita-500";
  font-size: 18px;
  color: var(--tp-common-white);
  background: rgba(255, 255, 255, 0.2);
  padding: 16px 25px;
  line-height: 1;
  display: inline-block;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .slider-text {
    font-size: 14px;
  }
}

.slider-title {
  color: var(--tp-common-white);
  font-size: 64px;
  line-height: 1.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-title {
    font-size: 54px;
  }
}
@media (max-width: 767px) {
  .slider-title {
    font-size: 34px;
    margin-bottom: 40px;
  }
}

.slider-info-list ul li {
  position: absolute;
  font-size: 16px;
  padding: 25px 20px;
  border: 1px solid var(--tp-common-white);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: block;
  z-index: 2;
  color: var(--tp-common-white);
}
.slider-info-list ul li span {
  margin-bottom: 5px;
  display: block;
  font-size: 28px;
  font-weight: var(--tp-fw-bold);
}
.slider-info-list ul li:nth-child(1) {
  right: 675px;
  top: 240px;
  background-color: #9B51E0;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .slider-info-list ul li:nth-child(1) {
    right: 475px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-info-list ul li:nth-child(1) {
    right: 320px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-info-list ul li:nth-child(1) {
    right: 220px;
  }
}
.slider-info-list ul li:nth-child(2) {
  bottom: 115px;
  right: 735px;
  background-color: #FF6652;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .slider-info-list ul li:nth-child(2) {
    bottom: 315px;
    right: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-info-list ul li:nth-child(2) {
    bottom: 280px;
    right: 25px;
  }
}
.slider-info-list ul li:nth-child(3) {
  bottom: 150px;
  right: 300px;
  background-color: #F2C94C;
}

.slider-content .slider-text {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.slider-content .slider-title {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
}
.slider-content .slider-btn {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
}

.slick-active .slider-content .slider-text, .slick-active .slider-content .slider-title, .slick-active .slider-content .slider-btn {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*----------------------------------------*/
/*  05. FEATURE CSS START
/*----------------------------------------*/
@media only screen and (min-width: 1601px) and (max-width: 1800px) {
  .tp-feature-area {
    padding-right: 85px;
    padding-left: 85px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-feature-area {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .tp-feature-area {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.f-shape-one {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  z-index: -1;
}

.tpfea {
  padding: 40px 30px 35px;
  background: var(--tp-common-white);
  box-shadow: 5px 15px 30px rgba(5, 13, 54, 0.06);
  border-radius: 10px;
}
.tpfea__icon i {
  height: 80px;
  width: 80px;
  line-height: 85px;
  text-align: center;
  color: var(--tp-common-white);
  background: var(--tp-border-2);
  display: inline-block;
  border-radius: 50px;
  font-size: 24px;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
}
.tpfea__text p {
  font-size: 16px;
  line-height: 28px;
  margin: 0;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpfea__text p {
    font-size: 14px;
  }
}
.tpfea__title {
  font-size: 24px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpfea__title {
    font-size: 22px;
  }
}
.tpfea:hover .tpfea__icon i {
  background: var(--tp-heading-secondary);
  transform: translate3d(0, -5px, 0);
}

.tp-feature-item {
  background-color: #F8FAF9;
  box-shadow: none;
  transition: 0.3s linear;
}
.tp-feature-item:hover {
  background: var(--tp-common-white);
  box-shadow: 5px 15px 30px rgba(5, 13, 54, 0.06);
}

/*----------------------------------------*/
/*  06. ABOUT CSS START
/*----------------------------------------*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-about-content {
    margin-left: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-about-content {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .tp-about-img {
    margin-left: 0;
  }
}

.tp-about-shape .a-shape-one {
  position: absolute;
  top: 105px;
  right: 20px;
  animation: ab2animation 5s infinite linear alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-about-shape .a-shape-one {
    top: 80px;
    right: -75px;
    width: 220px;
    height: auto;
  }
}
.tp-about-shape .a-shape-two {
  position: absolute;
  bottom: 85px;
  right: -70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-about-shape .a-shape-two {
    width: 180px;
    height: auto;
  }
}

.tp-about-line-shape .tp-aline-one {
  position: absolute;
  top: 90px;
  left: -65px;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-about-line-shape .tp-aline-one {
    top: 300px;
    left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-line-shape .tp-aline-one {
    top: 180px;
    right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-line-shape .tp-aline-one {
    top: 145px;
    left: -30px;
  }
}
.tp-about-line-shape .tp-aline-two {
  position: absolute;
  bottom: 300px;
  left: -60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-about-line-shape .tp-aline-two {
    left: -45px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-line-shape .tp-aline-two {
    left: -30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-line-shape .tp-aline-two {
    bottom: 220px;
    left: -40px;
    width: 110px;
  }
}
.tp-about-line-shape .tp-aline-three {
  position: absolute;
  top: -60px;
  right: 125px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-about-line-shape .tp-aline-three {
    right: -50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-line-shape .tp-aline-three {
    right: -60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-about-line-shape .tp-aline-three {
    top: -80px;
    right: -75px;
  }
}

.tp-ab-circle-img .ab-circle-one {
  position: absolute;
  top: 65px;
  right: 40px;
  animation: ab2animation 5s infinite linear alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-ab-circle-img .ab-circle-one {
    top: 0;
    right: 0px;
    width: 140px;
  }
}
@media (max-width: 767px) {
  .tp-ab-circle-img .ab-circle-one {
    top: 0;
    right: -5px;
    width: 120px;
  }
}
.tp-ab-circle-img .ab-circle-two {
  position: absolute;
  right: 21px;
  bottom: 155px;
  z-index: -1;
  animation: ab3animation 5s infinite linear alternate;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-ab-circle-img .ab-circle-two {
    right: 10px;
    bottom: 80px;
  }
}
@media (max-width: 767px) {
  .tp-ab-circle-img .ab-circle-two {
    right: 0;
    bottom: 45px;
  }
}

.about-circle-list ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.about-circle-list ul li {
  width: 50%;
  flex: 0 0 50%;
  font-size: 20px;
  font-family: "Gordita-500";
  font-weight: 500;
  color: var(--tp-heading-primary);
  line-height: 1.7;
  position: relative;
  padding-left: 50px;
  margin-bottom: 25px;
}
.about-circle-list ul li:hover i {
  background: var(--tp-border-2);
  color: var(--tp-common-white);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-circle-list ul li {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .about-circle-list ul li {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
  }
}
.about-circle-list ul li i {
  height: 40px;
  width: 40px;
  line-height: 40px;
  background: rgba(36, 93, 81, 0.1);
  color: var(--tp-border-2);
  text-align: center;
  display: block;
  border-radius: 50px;
  position: absolute;
  left: 0;
  font-size: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-abcircle-content {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .tp-abcircle-content p {
    font-size: 14px;
  }
}

.tp-about-class-info ul li {
  animation: ab2animation 5s infinite linear alternate;
  position: absolute;
  font-size: 16px;
  padding: 25px 20px;
  border: 1px solid var(--tp-common-white);
  background: var(--tp-common-white);
  border-radius: 5px;
  display: block;
  z-index: 2;
  color: var(--tp-text-2);
  box-shadow: 5px 8px 30px 0 rgba(17, 29, 59, 0.08);
  font-family: "Gordita-500";
  font-weight: 500;
}
@media (max-width: 767px) {
  .tp-about-class-info ul li {
    font-size: 16px;
    padding: 15px 15px;
  }
}
.tp-about-class-info ul li span {
  margin-bottom: 5px;
  display: block;
  font-size: 28px;
  font-weight: var(--tp-fw-bold);
  color: var(--tp-heading-primary);
}
@media (max-width: 767px) {
  .tp-about-class-info ul li span {
    margin-bottom: 0;
    font-size: 20px;
  }
}
.tp-about-class-info ul li span i {
  color: #F2C94C;
  margin-left: 12px;
}
.tp-about-class-info ul li:nth-child(1) {
  left: 0;
  top: 220px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-class-info ul li:nth-child(1) {
    top: 250px;
  }
}
@media (max-width: 767px) {
  .tp-about-class-info ul li:nth-child(1) {
    left: 0;
    top: 160px;
  }
}
.tp-about-class-info ul li:nth-child(2) {
  right: 5px;
  top: 205px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-class-info ul li:nth-child(2) {
    top: 45px;
  }
}
@media (max-width: 767px) {
  .tp-about-class-info ul li:nth-child(2) {
    right: 0;
    top: 0;
  }
}
.tp-about-class-info ul li:nth-child(3) {
  right: 90px;
  bottom: 160px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-about-class-info ul li:nth-child(3) {
    right: 0;
    bottom: 50px;
  }
}
@media (max-width: 767px) {
  .tp-about-class-info ul li:nth-child(3) {
    right: 0;
    bottom: 55px;
  }
}

.tp-about-list ul li {
  font-size: 20px;
  color: var(--tp-heading-primary);
  font-family: "Gordita-500";
  font-weight: 500;
  position: relative;
  padding-left: 50px;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .tp-about-list ul li {
    font-size: 16px;
  }
}
.tp-about-list ul li svg {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: var(--tp-border-2);
  background: rgba(36, 93, 81, 0.1);
  display: inline-block;
  border-radius: 50px;
  position: absolute;
  left: 0;
  font-size: 18px;
}
.tp-about-list ul li:not(:last-child) {
  margin-bottom: 35px;
}
.tp-about-list ul li:hover i {
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-about-class-content {
    margin-left: 0;
  }
}

.tp-sub-about-info ul li:nth-child(1) {
  left: 0px;
  top: 295px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-sub-about-info ul li:nth-child(1) {
    left: 0px;
    top: 150px;
  }
}
.tp-sub-about-info ul li:nth-child(2) {
  right: -25px;
  top: 500px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-sub-about-info ul li:nth-child(2) {
    right: -25px;
    top: 300px;
  }
}
.tp-sub-about-info ul li:nth-child(3) {
  right: 360px;
  bottom: 90px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-sub-about-info ul li:nth-child(3) {
    right: 250px;
    bottom: 90px;
  }
}

div.choose-sub-color {
  background-color: #F4F7F6;
}

.video-bg {
  border-radius: 20px;
  background: #F1F2F4;
  padding: 20px;
}
.video-bg > img {
  width: 100%;
  border-radius: 10px;
}

.video-text {
  position: absolute;
  top: 60px;
  left: 80px;
  padding: 10px 23px 10px 37px;
  background: var(--tp-common-white);
  border-radius: 50px;
}
@media (max-width: 767px) {
  .video-text {
    top: 25px;
    left: 25px;
    padding: 5px 8px 5px 22px;
  }
}
.video-text i {
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  display: block;
  position: relative;
  text-transform: uppercase;
}
.video-text i::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -15px;
  height: 10px;
  width: 10px;
  background: #EB5757;
  border-radius: 50px;
}
.video-text span {
  font-weight: 400;
  font-size: 14px;
  color: var(--tp-text-2);
  margin-left: 15px;
}
@media (max-width: 767px) {
  .video-text span {
    margin-left: 5px;
  }
}

@media (max-width: 767px) {
  .video-run-time {
    padding: 5px 10px 5px 25px;
  }
}

.video-shape {
  position: absolute;
  right: 0;
  top: -50px;
  z-index: -1;
}

.video-shape-2 {
  position: absolute;
  bottom: -50px;
  left: -55px;
  z-index: -1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-shape-2 {
    bottom: -30px;
    left: -25px;
    width: 110px;
  }
}

.video-shape-3 {
  position: absolute;
  bottom: 60px;
  left: 80px;
  background: var(--tp-common-white);
  padding: 10px;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-shape-3 {
    width: 100px;
    height: auto;
  }
}

/*----------------------------------------*/
/*  07. CATEGORY CSS START
/*----------------------------------------*/
.tp-cat-item {
  background: var(--tp-common-white);
  padding: 30px 30px;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tp-cat-item .tp-category-title {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 0;
  font-family: "Gordita-500";
  font-weight: 500;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-cat-item .tp-category-title {
    font-size: 20px;
  }
}
.tp-cat-item:hover {
  box-shadow: rgba(5, 13, 54, 0.05) 5px 15px 30px 0px;
}
.tp-cat-item:hover .tp-category-title {
  color: var(--tp-heading-secondary);
}
.tp-cat-item:hover .tp-category-icon svg {
  transform: rotateY(180deg);
}

.tp-category-icon svg {
  width: 40px;
  height: auto;
  transition-duration: 0.6s;
}

.tp-rec-item {
  box-shadow: rgba(5, 13, 54, 0.05) 5px 15px 30px 0px;
}
.tp-rec-item .tp-r-cat-title {
  margin-bottom: 15px;
}
.tp-rec-item p {
  margin-bottom: 0;
  line-height: 1.9;
}
.tp-rec-item .tp-category-icon {
  height: 40px;
}

.tp-cat-content .tp-title-small {
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-cat-content .tp-title-small {
    font-size: 20px;
  }
}
.tp-cat-content p {
  margin-bottom: 0;
}

.tp-cat-color span img {
  width: 35px;
  height: auto;
}

.tp-category-icon span {
  display: inline-block;
  width: 75px;
  height: 75px;
  line-height: 65px;
  text-align: center;
  border-radius: 50%;
}

.cat-design {
  background-color: rgba(235, 86, 86, 0.1);
}

.cat-deve {
  background-color: rgba(242, 153, 74, 0.1);
}

.cat-market {
  background-color: rgba(242, 201, 76, 0.1);
}

.cat-it {
  background-color: rgba(33, 150, 83, 0.1);
}

.cat-video {
  background-color: rgba(47, 128, 237, 0.1);
}

.cat-business {
  background-color: rgba(47, 128, 237, 0.1);
}

.cat-photo {
  background-color: rgba(155, 81, 224, 0.1);
}

.cat-music {
  background-color: rgba(45, 156, 219, 0.1);
}

/*----------------------------------------*/
/*  08. COURSE CSS START
/*----------------------------------------*/
.course-menu span {
  font-size: 20px;
  display: block;
  font-family: "Gordita-500";
  font-weight: 500;
  margin-right: 13px;
}
.course-menu .tp-course-menu ul li a {
  display: block;
  padding: 12px 30px;
  background: rgba(255, 102, 82, 0.05);
  font-size: 16px;
  font-family: "Gordita-500";
  font-weight: 500;
  color: var(--tp-heading-secondary);
}
.course-menu .tp-course-menu ul li a i {
  margin-left: 10px;
}

.tpcourse-thumb-w {
  width: 36.7%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpcourse-thumb-w {
    width: 25%;
  }
}
@media (max-width: 767px) {
  .tpcourse-thumb-w {
    width: 100%;
  }
}

.tpcourse-thumb-text {
  width: 63.3%;
  padding-left: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpcourse-thumb-text {
    width: 75%;
  }
}
@media (max-width: 767px) {
  .tpcourse-thumb-text {
    width: 100%;
    padding-left: 15px;
    margin-top: 20px;
  }
}

.tpcourse__thumb {
  transform: scale(1);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  border-radius: 5px;
  display: block;
}
.tpcourse__tag {
  position: absolute;
  top: 25px;
  right: 25px;
}
.tpcourse__tag i {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 20px;
  text-align: center;
  background: var(--tp-common-white);
  color: var(--tp-heading-secondary);
  border-radius: 50px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tpcourse__tag:hover i {
  background-color: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}
.tpcourse__img-icon {
  position: absolute;
  bottom: 10px;
  left: 20px;
  border: 2px solid var(--tp-common-white);
  border-radius: 50%;
}
.tpcourse__content {
  padding: 25px 25px 30px 25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 0 0 5px 5px;
}
.tpcourse__title {
  font-size: 20px;
  line-height: 1.5;
  font-family: "Gordita-500";
  margin: 0 0 0 13px;
  font-weight: 500;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), (max-width: 767px) {
  .tpcourse__title {
    font-size: 16px;
    line-height: 1.4;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpcourse__title {
    font-size: 18px;
    line-height: 1.4;
  }
}
.tpcourse__title a:hover {
  color: var(--tp-heading-secondary);
}
.tpcourse__meta {
  border-bottom: 1px solid rgba(5, 13, 54, 0.1);
}
.tpcourse__meta ul {
  flex-wrap: wrap;
}
.tpcourse__meta ul li {
  align-items: center;
  display: flex;
  margin-right: 25px;
}
.tpcourse__meta ul li:last-child {
  margin-right: 0px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpcourse__meta ul li {
    margin-right: 21px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tpcourse__meta ul li {
    margin-right: 11px;
  }
}
.tpcourse__meta ul li img {
  margin-right: 5px;
}
@media (max-width: 767px) {
  .tpcourse__meta ul li img {
    margin-right: 3px;
  }
}
.tpcourse__meta ul li span {
  font-size: 16px;
  color: var(--tp-text-2);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tpcourse__meta ul li span {
    font-size: 14px;
  }
}
.tpcourse__category ul li {
  margin-right: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tpcourse__category ul li {
    margin-right: 5px;
  }
}
.tpcourse__category ul li a {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  display: block;
  padding: 13px 18px;
  background: rgba(255, 102, 82, 0.1);
  border-radius: 5px;
  font-size: 16px;
  color: var(--tp-heading-primary);
  line-height: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpcourse__category ul li a {
    padding: 12px 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpcourse__category ul li a {
    padding: 12px 12px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .tpcourse__category ul li a {
    font-size: 14px;
    padding: 12px 10px;
  }
}
.tpcourse__category ul li a:hover {
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}
.tpcourse__course-price {
  font-size: 20px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .tpcourse__course-price {
    font-size: 16px;
  }
}
.tpcourse__price-list {
  font-family: "Gordita-500";
  font-weight: 500;
}
.tpcourse:hover .tpcourse__thumb a img {
  transform: scale(1.1);
  transition: 0.5s;
}

.tp-wrap-course {
  padding: 25px;
  background: #fff;
}
.tp-wrap-course__title {
  font-size: 20px;
  line-height: 1.3;
  font-family: "Gordita-500";
  font-weight: 500;
}
.tp-wrap-course__title:hover {
  color: var(--tp-heading-secondary);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-wrap-course__title {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-course-line {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-course-line ul li:not(:last-child) {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-course-line ul li span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .c-price-pac {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .c-price-list ul li a {
    padding: 10px;
  }
}

.tp-cours-title-color {
  font-size: 22px;
  margin-left: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-cours-title-color {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .tp-cours-title-color {
    font-size: 16px;
  }
}

.tpcourse__content-2 {
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 0 0 5px 5px;
}
.tpcourse__rating-icon span {
  color: #F2C94C;
  font-size: 20px;
  font-family: "Gordita-500";
  font-weight: 500;
}
.tpcourse__rating-icon i {
  color: #F2C94C;
  font-size: 16px;
}
.tpcourse__rating-icon p {
  margin: 0 0 0 2px;
  display: inline-block;
  color: var(--tp-text-2);
}
.tpcourse .price-title {
  font-size: 20px;
  font-family: "Gordita-500";
  font-weight: 400;
  margin-bottom: 0;
}

.tpcourse__meta-gap ul li {
  margin-right: 30px;
}
.tpcourse__meta-gap ul li:last-child {
  margin-right: 0px;
}
.tpcourse__meta-gap ul li span {
  font-size: 17px;
}
@media (max-width: 767px) {
  .tpcourse__meta-gap ul li span {
    font-size: 14px;
  }
}

.tpcourse__price-list .c-color-yellow {
  background-color: rgba(242, 153, 74, 0.1);
  color: #F2994A;
}
.tpcourse__price-list .c-color-yellow:hover {
  background: #F2994A;
  color: var(--tp-common-white);
}
.tpcourse__price-list .c-color-light-yellow {
  background-color: rgba(242, 201, 76, 0.1);
  color: #F2C94C;
}
.tpcourse__price-list .c-color-light-yellow:hover {
  background: #F2C94C;
  color: var(--tp-common-white);
}
.tpcourse__price-list .c-color-aqua {
  background-color: rgba(86, 204, 242, 0.1);
  color: #56CCF2;
}
.tpcourse__price-list .c-color-aqua:hover {
  background: #56CCF2;
  color: var(--tp-common-white);
}
.tpcourse__price-list .c-color-red {
  background-color: rgba(235, 87, 87, 0.1);
  color: #EB5757;
}
.tpcourse__price-list .c-color-red:hover {
  background: #eb5757;
  color: var(--tp-common-white);
}
.tpcourse__price-list .c-color-green {
  background-color: rgba(33, 150, 83, 0.1);
  color: #219653;
}
.tpcourse__price-list .c-color-green:hover {
  background: #219653;
  color: var(--tp-common-white);
}
.tpcourse__price-list .c-color-blue {
  background-color: rgba(47, 128, 237, 0.1);
  color: #2F80ED;
}
.tpcourse__price-list .c-color-blue:hover {
  background: #2f80ed;
  color: var(--tp-common-white);
}
.tpcourse__price-list .c-color-purple {
  background-color: rgba(155, 81, 224, 0.1);
  color: #9B51E0;
}
.tpcourse__price-list .c-color-purple:hover {
  background: #9b51e0;
  color: var(--tp-common-white);
}

.tp-course-tab {
  padding: 12px 38px;
  background: rgba(255, 102, 82, 0.1);
  color: var(--tp-heading-secondary);
  font-size: 16px;
  border-radius: 50px;
  position: relative;
  font-family: "Gordita-500";
  font-weight: 500;
  position: relative;
  margin: 0 5px 20px 5px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-course-tab {
    padding: 10px 24px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .tp-course-tab {
    padding: 8px 20px;
    font-size: 14px;
  }
}
.tp-course-tab::before {
  position: absolute;
  content: "";
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  height: 9px;
  width: 8px;
  background: var(--tp-heading-secondary);
  bottom: -9px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  visibility: hidden;
}
.tp-course-tab:hover, .tp-course-tab.active {
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}
.tp-course-tab:hover::before, .tp-course-tab.active::before {
  opacity: 1;
  visibility: visible;
}

.courser-list-width {
  width: 37%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .courser-list-width {
    width: 100%;
  }
}

.course-item-width {
  width: 60.5%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .course-item-width {
    width: 59.5%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .course-item-width {
    width: 100%;
    margin-left: 0;
  }
}

.course-sidebar {
  padding: 40px 40px;
  box-shadow: 5px 15px 30px 10px rgba(5, 13, 54, 0.03);
  border-radius: 10px;
}
.course-sidebar__title {
  color: var(--tp-color-primary);
  font-weight: 500;
  font-size: 18px;
  font-family: "Gordita-500";
}

.c-info-list .form-check {
  margin-bottom: 10px;
}
.c-info-list .form-check span {
  color: var(--tp-heading-secondary);
  font-size: 14px;
  padding: 5px;
  background-color: rgba(255, 102, 82, 0.1);
  border-radius: 5px;
  line-height: 1;
}
.c-info-list .form-check-input {
  background-color: #DBDDE2;
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 5px;
}
.c-info-list .form-check-input:checked {
  background-color: var(--tp-heading-secondary);
}
.c-info-list .form-check-input[type=checkbox] {
  border-radius: 5px;
}
.c-info-list .form-check-input:focus {
  box-shadow: none;
  border: none;
}
.c-info-list .form-check-label {
  font-weight: 500;
  font-size: 16px;
  font-family: "Gordita-500";
  color: var(--tp-text-2);
  padding-left: 15px;
}
.c-info-list .form-check-input:checked[type=checkbox] {
  background-image: none;
  position: relative;
}
.c-info-list .form-check-input:checked[type=checkbox]::before {
  position: absolute;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  left: 7px;
  color: var(--tp-common-white);
}

.course-thumb-width {
  width: 32.5%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .course-thumb-width {
    width: 100%;
  }
}

.course-text-width {
  width: 67.5%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .course-text-width {
    width: 100%;
  }
}

.nice-select {
  float: none;
  background: #F2F3F5;
}
.nice-select::after {
  height: 8px;
  width: 8px;
  border-bottom: 1px solid #050D36;
  border-right: 1px solid #050D36;
  margin-right: 10px;
}

.course-list-content {
  padding: 15px;
  box-shadow: none;
  padding: 20px 5px 5px 20px;
}

.tp-list-course {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 15px;
}

.c-details-thumb > img {
  width: 100%;
  border-radius: 10px;
}
.c-details-thumb::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(5, 13, 54, 0) 0%, rgba(5, 13, 54, 0.4) 100%);
}

.c-details-ava {
  position: absolute;
  bottom: 30px;
  left: 30px;
}
@media (max-width: 767px) {
  .c-details-ava {
    bottom: 10px;
    left: 10px;
  }
}
.c-details-ava span {
  margin-left: 15px;
  color: var(--tp-common-white);
  font-weight: 500;
  font-family: "Gordita-500";
}
.c-details-ava span a:hover {
  color: var(--tp-heading-secondary);
}

.c-details-title {
  color: var(--tp-heading-primary);
  font-size: 44px;
  line-height: 1.4;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 768px) and (max-width: 991px) {
  .c-details-title {
    font-size: 38px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .c-details-title {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  .c-details-title {
    font-size: 24px;
  }
}

.course-details-list {
  border: none;
}
.course-details-list ul li {
  margin-right: 40px;
}
@media (max-width: 767px) {
  .course-details-list ul li {
    margin-right: 20px;
  }
}

.tp-c-details-title {
  color: var(--tp-heading-primary);
  font-size: 30px;
}
@media (max-width: 767px) {
  .tp-c-details-title {
    font-size: 24px;
  }
}

.c-details-about p {
  font-size: 17px;
}

.course-avata img {
  border-radius: 5px;
}

.course-avatar-details p {
  font-size: 17px;
  color: var(--tp-text-2);
}

.c-avata-title {
  color: var(--tp-heading-primary);
  font-size: 30px;
}
@media (max-width: 767px) {
  .c-avata-title {
    font-size: 20px;
  }
}

.course-avata {
  width: 190px;
}

.c-details-list ul li {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .c-details-list ul li {
    margin-right: 15px;
  }
}
.c-details-list ul li img {
  margin-right: 8px;
}
.c-details-list ul li span {
  font-size: 16px;
  color: var(--tp-text-2);
}

.cor-details-instructor p {
  font-size: 17px;
}

.c-details-stu ul li span {
  margin-left: 8px;
  font-size: 16px;
  color: var(--tp-text-2);
}

.c-review-title {
  color: var(--tp-heading-primary);
  font-size: 30px;
}
@media (max-width: 767px) {
  .c-review-title {
    font-size: 20px;
  }
}

.course-ava-title {
  font-size: 20px;
  color: var(--tp-heading-primary);
  font-weight: 400;
  font-family: "Gordita-500";
}

.course-review-ava {
  flex: 0 0 auto;
  margin-right: 25px;
  width: 65px;
}

.rating-gold p {
  font-weight: 500;
  font-size: 18px;
  color: #F2C94C;
  margin-bottom: 0;
  font-family: "Gordita-500";
  margin-right: 5px;
}
.rating-gold i {
  color: #F2C94C;
}
.rating-gold > span {
  margin-left: 5px;
  font-weight: 400;
  font-size: 16px;
}

.c-reviewer-time {
  position: absolute;
  right: 45px;
  top: 0;
}
.c-reviewer-time span {
  font-weight: 400;
  font-size: 17px;
  color: var(--tp-text-2);
}
@media (max-width: 767px) {
  .c-reviewer-time {
    position: static;
  }
}

.c-details-sidebar {
  padding: 15px 15px 50px 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
}
.c-details-sidebar .course-details-widget {
  padding: 0 15px;
}

.c-video-thumb img {
  border-radius: 5px;
  width: 100%;
}
.c-video-thumb .c-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c-video-thumb .c-video-icon a {
  height: 70px;
  width: 70px;
  line-height: 70px;
  background: var(--tp-border-2);
  color: var(--tp-common-white);
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  display: block;
  animation: tp-pulse 2s infinite;
}

.cd-video-price .pricing-video {
  font-size: 36px;
  color: var(--tp-color-primary);
}

.cd-pricing-btn .tp-vp-btn, .cd-pricing-btn .tp-vp-btn-green {
  display: block;
  padding: 20px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Gordita-500";
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
  border-radius: 10px;
  margin-bottom: 10px;
}
.cd-pricing-btn .tp-vp-btn-green {
  background-color: var(--tp-border-2);
}

.cd-information ul li {
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(5, 13, 54, 0.1);
}
.cd-information ul li:hover i {
  background-color: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}
.cd-information ul li i {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid rgba(5, 13, 54, 0.1);
  border-radius: 50%;
  font-size: 18px;
  display: inline-block;
  margin-right: 10px;
  color: var(--tp-heading-primary);
  transition: 0.2s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cd-information ul li i {
    margin-right: 5px;
  }
}
.cd-information ul li label {
  font-weight: 500;
  font-size: 20px;
  font-family: "Gordita-500";
  color: var(--tp-heading-primary);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .cd-information ul li label {
    font-size: 16px;
  }
}
.cd-information ul li span {
  float: right;
  font-size: 18px;
  color: var(--tp-text-2);
  margin-top: 12px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .cd-information ul li span {
    font-size: 14px;
  }
}

.c-details-social .cd-social-title {
  font-size: 22px;
  font-weight: 500;
  font-family: "Gordita-500";
  color: var(--tp-color-primary);
}
.c-details-social a {
  margin-right: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .c-details-social a {
    margin-right: 10px;
  }
}
.c-details-social a i {
  height: 50px;
  width: 50px;
  line-height: 52px;
  text-align: center;
  font-size: 20px;
  background-color: rgba(255, 102, 82, 0.1);
  color: var(--tp-heading-secondary);
  border-radius: 50%;
  transition: 0.4s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .c-details-social a i {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}
.c-details-social a i:hover {
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}

/*----------------------------------------*/
/*  09. CHOOSE CSS START
/*----------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-choose-img {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .tp-choose-img-2 {
    margin-right: 0;
  }
}

.tpchoose-img-text ul li {
  animation: abanimation 5s infinite linear alternate;
  position: absolute;
  padding: 25px 17px 12px;
  border: 1px solid var(--tp-common-white);
  background: var(--tp-common-white);
  border-radius: 5px;
  display: block;
  z-index: 9;
  color: var(--tp-common-black);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.tpchoose-img-text ul li svg {
  font-size: 17px;
  font-style: normal;
  font-weight: var(--tp-fw-bold);
  clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 48px;
  display: block;
}
.tpchoose-img-text ul li p {
  margin: 15px 0 0 0;
  color: var(--tp-heading-primary);
  font-family: "Gordita-500";
}
.tpchoose-img-text ul li:nth-child(1) {
  left: -85px;
  top: 150px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tpchoose-img-text ul li:nth-child(1) {
    left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpchoose-img-text ul li:nth-child(1) {
    top: 80px;
    left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tpchoose-img-text ul li:nth-child(1) {
    left: 0px;
  }
}
.tpchoose-img-text ul li:nth-child(1) i {
  background: var(--tp-border-2);
  color: var(--tp-common-white);
  font-size: 20px;
  padding: 8px;
}
.tpchoose-img-text ul li:nth-child(2) {
  top: 335px;
  right: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tpchoose-img-text ul li:nth-child(2) {
    top: 275px;
    right: 20px;
  }
}
.tpchoose-img-text ul li:nth-child(2) svg {
  color: var(--tp-common-white);
  background: var(--tp-heading-secondary);
}

.tp-choose-list ul li {
  font-size: 20px;
  color: var(--tp-heading-primary);
  font-family: "Gordita-500";
  position: relative;
  display: inline-block;
  margin-bottom: 28px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-choose-list ul li {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .tp-choose-list ul li {
    font-size: 14px;
  }
  .pr-205{
    padding: 0px !important;
  }
}
.tp-choose-list ul li svg {
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  color: var(--tp-common-white);
  background: var(--tp-border-2);
  display: inline-block;
  border-radius: 50px;
  position: absolute;
  left: 0;
  font-size: 14px;
}
.tp-choose-list ul li:hover svg {
  background: var(--tp-heading-secondary);
}

.tp-choose-bg ul li {
  padding-left: 0px;
}
.tp-choose-bg ul li svg {
  left: 20px;
  background: rgba(36, 93, 81, 0.1);
}

.tp-list-bg {
  background: var(--tp-common-white);
  position: relative;
  padding: 10px 21px 12px 60px;
  display: inline-block;
  border-radius: 5px;
  font-size: 18px;
}
.tp-list-bg:hover.tp-list-bg i {
  color: var(--tp-common-white);
}
@media (max-width: 767px) {
  .tp-list-bg {
    padding: 10px 20px 10px 55px;
    font-size: 14px;
  }
}

.tp-chose-shape ul li:nth-child(1) {
  right: -50px;
  left: auto;
  bottom: 150px;
  top: auto;
  text-align: left;
}
.tp-chose-shape ul li:nth-child(2) {
  top: 195px;
  left: 125px;
  right: auto;
  text-align: left;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-chose-shape ul li:nth-child(2) {
    left: -10px;
    top: 0;
  }
}
.tp-chose-shape ul li:nth-child(3) {
  text-align: left;
  padding-bottom: 20px;
  bottom: -30px;
  left: 50px;
}
.tp-chose-shape ul li:nth-child(3) p {
  margin-top: 0;
  margin-bottom: 20px;
}

.tp-big-bg img {
  max-width: inherit;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-big-bg img {
    max-width: 100%;
  }
}

.tp-choose-online-list ul li:not(:last-child) {
  margin-bottom: 18px;
}

.choose-online-icon i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: block;
  background: var(--tp-border-2);
  color: var(--tp-common-white);
  border-radius: 50%;
  margin-right: 8px;
}

.choose-online-title {
  font-size: 22px;
  font-family: "Gordita-500";
  font-weight: 500;
  line-height: 1;
  display: inline-block;
}
@media (max-width: 767px) {
  .choose-online-title {
    line-height: 1.2;
  }
}
.choose-online-title:hover {
  color: var(--tp-heading-secondary);
}

.choose-online-content p {
  margin-bottom: 0;
}

/*----------------------------------------*/
/*  10. COUNTER CSS START
/*----------------------------------------*/
.counter-item__icon i {
  height: 80px;
  width: 80px;
  line-height: 85px;
  text-align: center;
  display: inline-block;
  background: var(--tp-border-2);
  color: var(--tp-common-white);
  border-radius: 50px;
  font-size: 28px;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
}
.counter-item__content p {
  margin-bottom: 0;
  font-size: 24px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-item__content p {
    font-size: 20px;
  }
}
.counter-item__title {
  font-size: 44px;
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 20px;
}
.counter-item__title::before {
  position: absolute;
  content: "";
  bottom: 0;
  height: 3px;
  width: 50px;
  background: var(--tp-heading-secondary);
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-item__title {
    font-size: 36px;
  }
}
.counter-item:hover .counter-item__icon i {
  transform: translate3d(0, -5px, 0);
}

.counter-white-text p {
  font-size: 22px;
  color: var(--tp-common-white);
  font-family: "Gordita-500";
  font-weight: 500;
}

.counter-left-title {
  color: var(--tp-common-white);
}
.counter-left-title::before {
  right: 0;
  margin: 0;
}

.counter-b-border {
  border-bottom: 1px solid #668E85;
}

/*----------------------------------------*/
/*  11. INSTRUCTOR CSS START
/*----------------------------------------*/
.tp-instructor {
  border: 1px solid rgba(119, 124, 144, 0.1);
  padding: 20px 20px 22px;
  background: var(--tp-common-white);
  border-radius: 10px;
}
.tp-instructor__thumb {
  position: relative;
}
.tp-instructor__thumb::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(205, 203, 214, 0.1), rgba(157, 154, 175, 0.2), rgba(111, 108, 136, 0.3), rgba(66, 65, 100, 0.4), rgba(47, 47, 84, 0.5), rgba(27, 30, 69, 0.6), rgba(5, 13, 54, 0.7), rgba(5, 13, 54, 0.8), rgba(5, 13, 54, 0.9), rgb(5, 13, 54), rgb(5, 13, 54));
  opacity: 0;
  border-radius: 10px;
}
.tp-instructor__thumb img {
  border-radius: 10px;
  width: 100%;
}
.tp-instructor__content span {
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Gordita-500";
  color: var(--tp-border-2);
}
.tp-instructor__title {
  font-size: 24px;
}
.tp-instructor__title a {
  display: inline-block;
}
.tp-instructor__title a:hover {
  color: var(--tp-heading-secondary);
}
.tp-instructor__social {
  position: absolute;
  top: 102px;
  right: 38px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-instructor__social {
    top: 80px;
  }
}
.tp-instructor__social ul li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 30px;
  text-align: center;
  font-size: 25px;
  border-radius: 50px;
  background: var(--tp-common-white);
  color: var(--tp-heading-secondary);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-instructor__social ul li a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}
.tp-instructor__social ul li a:hover {
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}
.tp-instructor__social ul li + li {
  margin-top: 10px;
}
.tp-instructor:hover .tp-instructor__social {
  opacity: 1;
  transform: translateY(-10px);
}
.tp-instructor:hover .tp-instructor__thumb::before {
  opacity: 0.3;
}
.tp-instructor__title-info::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100px;
  background: var(--tp-heading-secondary);
  bottom: -17px;
  left: 0;
  right: 0;
  margin: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-instructor__title-info {
    font-size: 20px;
  }
}
.tp-instructor__stu-info ul li {
  font-size: 16px;
  color: var(--tp-text-2);
  margin: 0 16px;
}
.tp-instructor__stu-info ul li i {
  margin-left: 4px;
  font-style: normal;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-instructor__stu-info ul li {
    font-size: 14px;
    margin: 0 8px;
  }
}
@media (max-width: 767px) {
  .tp-instructor__stu-info ul li {
    margin: 0 10px;
  }
}

.tp-instruc-arrow {
  justify-content: end;
}
@media (max-width: 767px) {
  .tp-instruc-arrow {
    margin-bottom: 40px;
    justify-content: flex-start;
  }
}

.tp-instruc-arrow .slick-arrow {
  height: 60px;
  width: 60px;
  background: #FFF0EE;
  color: var(--tp-heading-secondary);
  border-radius: 50px;
  font-size: 50px;
  line-height: 60px;
}
.tp-instruc-arrow .slick-arrow:hover {
  color: var(--tp-common-white);
  background: var(--tp-heading-secondary);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-instruc-arrow .slick-arrow {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 40px;
  }
}
.tp-instruc-arrow .slick-prev {
  margin-right: 20px;
}

.instruc-sidebar {
  padding: 15px 15px 50px 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
}

.isntruc-side-thumb img {
  width: 100%;
  border-radius: 5px;
}

.instructor-sidebar-widget {
  padding: 0 15px;
}

.isntruc-side-content .side-instructor-title {
  font-size: 24px;
  color: var(--tp-heading-primary);
}
.isntruc-side-content p {
  font-size: 17px;
  color: var(--tp-text-2);
  margin-bottom: 25px;
}

.ins-followers, .ins-following {
  margin: 0 25px;
}
.ins-followers h4, .ins-following h4 {
  text-align: center;
  font-size: 24px;
  color: var(--tp-heading-primary);
}
.ins-followers span, .ins-following span {
  font-size: 18px;
  color: var(--tp-text-2);
}

.instruc-side-btn .ins-btn {
  display: block;
  padding: 20px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Gordita-500";
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
  border-radius: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .instruc-side-btn .ins-btn {
    padding: 15px 20px;
  }
}
.instruc-side-btn .ins-btn:hover {
  background-color: var(--tp-border-2);
}

.instruc-biography .ins-bio-title {
  font-size: 24px;
  color: var(--tp-heading-primary);
}

.isntruc-tp-counter__title {
  color: var(--tp-heading-primary);
  font-size: 36px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.isntruc-tp-counter__title::before {
  position: absolute;
  content: "";
  bottom: 0;
  height: 3px;
  width: 50px;
  background: var(--tp-heading-secondary);
  left: 0;
  border-radius: 10px;
}
.isntruc-tp-counter p {
  font-size: 18px;
  font-family: "Gordita-500";
  font-weight: 500;
  color: var(--tp-text-2);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), (max-width: 767px) {
  .instruc-profile-info ul li i {
    margin-right: 5px;
  }
}
@media (max-width: 767px) {
  .instruc-profile-info ul li i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .instruc-profile-info ul li label {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .instruc-profile-info ul li label {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .instruc-profile-info ul li span {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .instruc-profile-info ul li span {
    margin-top: 7px;
  }
}

@media (max-width: 767px) {
  .instructor-main-content {
    margin-left: 0;
  }
}

/*----------------------------------------*/
/*  12. SUITABLE CSS START
/*----------------------------------------*/
.tp-suit {
  padding: 52px 20px 50px 30px;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.tp-suit__content {
  width: 220px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-suit__content {
    width: 100%;
  }
}
.tp-suit__title {
  font-size: 24px;
  color: var(--tp-common-black);
  line-height: 34px;
}
.tp-suit__title span {
  color: var(--tp-heading-secondary);
}
.tp-suit__img {
  position: absolute;
  bottom: 16px;
  right: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-suit__img {
    bottom: 35px;
    width: 260px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-suit__img {
    width: 300px;
  }
}
@media (max-width: 767px) {
  .tp-suit__img {
    position: static;
  }
}
.tp-suit__tech {
  position: absolute;
  bottom: 45px;
  right: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-suit__tech {
    width: 260px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-suit__tech {
    width: 300px;
  }
}
@media (max-width: 767px) {
  .tp-suit__tech {
    position: static;
  }
}

/*----------------------------------------*/
/*  13. TESTIMONIAL CSS START
/*----------------------------------------*/
.testimonial-area {
  background-color: #F8FAFA;
}

.tp-testi {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 35px 30px 34px;
  background: var(--tp-common-white);
  border-radius: 15px;
  box-shadow: 0px 0px 10px 3px rgba(5, 13, 54, 0.05);
}
.tp-testi__avatar {
  position: absolute;
  top: -62px;
  left: 0;
}
.tp-testi__rating {
  color: #F2C94C;
}
.tp-testi__rating i {
  font-size: 18px;
}
.tp-testi__rating span {
  color: var(--tp-heading-primary);
  font-size: 18px;
  font-family: "Gordita-500";
  font-weight: 500;
  margin-left: 5px;
}
.tp-testi__avainfo p {
  font-size: 17px;
  line-height: 1.8;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-testi__avainfo p {
    font-size: 14px;
    line-height: 26px;
  }
}
@media (max-width: 767px) {
  .tp-testi__avainfo p {
    font-size: 16px;
  }
}
.tp-testi__avainfo i {
  color: var(--tp-border-2);
  font-style: normal;
  font-size: 16px;
}
.tp-testi__title {
  margin-bottom: 8px;
}
.tp-testi p {
  margin-bottom: 16px;
}
.tp-testi__rating-count {
  font-size: 18px;
  color: var(--tp-heading-primary);
  font-family: "Gordita-500";
  line-height: 1;
}

.tp-slide-space {
  margin: 0 -15px;
}
.tp-slide-space .slick-track {
  padding: 30px 0px;
}
.tp-slide-space .slick-slide {
  padding: 0 15px;
}

.tp-section-arrow .slick-arrow {
  height: 60px;
  width: 60px;
  background: #FFF0EE;
  color: var(--tp-heading-secondary);
  border-radius: 50px;
  font-size: 50px;
  line-height: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-section-arrow .slick-arrow {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 40px;
  }
}
.tp-section-arrow .slick-arrow:hover {
  color: var(--tp-common-white);
  background: var(--tp-heading-secondary);
}
.tp-section-arrow .slick-prev {
  margin-right: 20px;
}

.testi-ava-border {
  border-bottom: 2px solid #E6E7EB;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .testi-ava-border img {
    width: 80px;
    height: auto;
  }
}

.testi-quote {
  position: absolute;
  top: -40px;
  right: 40px;
  height: 80px;
  width: 80px;
  line-height: 80px;
  background: var(--tp-border-2);
  text-align: center;
  color: var(--tp-common-white);
  border-radius: 50%;
  font-size: 36px;
}

.testimonial-active-box .slick-list {
  padding-top: 40px;
}

.tp-slide-space-white {
  margin: 0 -30px;
}
.tp-slide-space-white .slick-track {
  padding-bottom: 30px;
}
.tp-slide-space-white .slick-slide {
  padding: 0 30px;
}

@media (max-width: 767px) {
  .tp-title-meta {
    font-size: 18px;
  }
}

/*----------------------------------------*/
/*  14. BRAND CSS START
/*----------------------------------------*/
.brand-item {
  text-align: center;
}
.brand-item a {
  display: inline-block;
}

/*----------------------------------------*/
/*  15. MEANMENU CSS START
/*----------------------------------------*/
/* mean menu customize */
.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}

.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 10px 0;
  color: var(--clr-common-black);
  border-top: 1px solid #ebebeb;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
}
.mean-container .mean-nav ul li a:hover {
  color: var(--clr-theme-1);
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 5px;
  padding: 0 !important;
  line-height: 14px;
  border: 1px solid #ebebeb !important;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: var(--clr-common-black);
  line-height: 30px;
  top: 0;
  font-weight: 400;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
  background: var(--clr-theme-1);
  color: var(--clr-common-white);
  border-color: var(--clr-theme-1);
}

.mean-container .mean-nav ul li > a > i {
  display: none;
}

.mean-container .mean-nav ul li > a.mean-expand i {
  display: inline-block;
}

.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
  color: var(--clr-common-black);
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  color: var(--clr-common-black);
}

/*----------------------------------------*/
/*  16. BLOG CSS START
/*----------------------------------------*/
.tp-blog {
  border: 1px solid rgba(119, 124, 144, 0.1);
  border-radius: 10px;
  transition: 0.3s;
}
.tp-blog__thumb {
  border-radius: 10px;
  transform: scale(1);
}
.tp-blog__thumb img {
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  width: 100%;
}
.tp-blog__timg {
  border-radius: 10px;
}
.tp-blog__icon {
  position: absolute;
  bottom: -30px;
  right: 0px;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.tp-blog__icon a {
  height: 60px;
  width: 60px;
  line-height: 60px;
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  font-size: 20px;
}
.tp-blog__content {
  padding: 30px 20px 25px 26px;
}
.tp-blog__content p {
  margin-bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-blog__content p {
    font-size: 14px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-blog__content p {
    font-size: 17px;
  }
}
.tp-blog__content > span {
  font-size: 16px;
  color: var(--tp-text-2);
  line-height: 1;
  display: inline-block;
}
.tp-blog__meta {
  color: var(--tp-heading-secondary);
  text-transform: uppercase;
}
.tp-blog__title {
  color: #161B2D;
  line-height: 1.6;
  font-family: "Gordita-500";
  font-weight: 500;
}
.tp-blog__title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-blog__title {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .tp-blog__title {
    font-size: 20px;
  }
}
.tp-blog__title:hover a {
  color: #000;
  background-size: 0 1px, 100% 1px;
}
.tp-blog__meta-ab {
  position: absolute;
  bottom: -25px;
  left: 28px;
  color: var(--tp-heading-secondary);
  background: var(--tp-common-white);
  padding: 12px 11px;
  font-size: 16px;
  font-family: "Gordita-500";
  font-weight: 500;
  border-radius: 5px;
  box-shadow: rgba(5, 13, 54, 0.05) 5px 15px 30px 0px;
}
.tp-blog:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.tp-blog:hover .tp-blog__thumb img {
  transform: scale(1.1);
}
.tp-blog:hover .tp-blog__icon {
  opacity: 1;
  right: 30px;
}
.tp-blog .blog-round {
  border-radius: 10px 10px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-box .tp-blog__title {
    font-size: 18px;
  }
}
.blog-box p {
  margin-bottom: 18px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-box p {
    font-size: 14px;
  }
}

.tp-blog-parent {
  box-shadow: rgba(17, 29, 59, 0.05) 0px 8px 24px;
  border: none;
}

.blog-edu {
  padding: 23px 20px 23px 30px;
}
.blog-edu span {
  color: var(--tp-text-2);
  font-size: 18px;
  line-height: 1;
  font-family: "Gordita-500";
  font-weight: 500;
}
.blog-edu span i {
  margin-right: 3px;
}
.blog-edu span:hover {
  color: var(--tp-heading-secondary);
}

.tp-blog__meta-list span {
  color: var(--tp-text-2);
  font-size: 17px;
  line-height: 1;
  font-family: "Gordita-500";
  font-weight: 500;
  margin-right: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-blog__meta-list span {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .tp-blog__meta-list span {
    margin-right: 15px;
  }
}
.tp-blog__meta-list span i {
  margin-right: 5px;
}
.tp-blog__meta-list span:hover {
  color: var(--tp-heading-secondary);
}

.tpproject-2-title a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1.5px, 0 1.5px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .postbox__wrapper {
    padding-right: 0;
  }
}
.postbox__thumb .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  z-index: 1;
  height: 90px;
  width: 90px;
  background: var(--tp-common-white);
  text-align: center;
  border-radius: 50%;
  line-height: 88px;
  font-size: 18px;
  animation: tp-pulse 2s;
}
.postbox__thumb .play-btn:hover {
  color: var(--tp-heading-secondary);
}
.postbox__audio {
  height: 455px;
  width: 100%;
}
.postbox__audio iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.postbox__item-single:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.postbox__title {
  font-size: 34px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__title {
    font-size: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .postbox__title {
    font-size: 33px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .postbox__title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .postbox__title {
    font-size: 25px;
  }
}
.postbox__title a:hover {
  color: var(--tp-theme-1);
}
.postbox__meta {
  margin-bottom: 15px;
}
.postbox__meta span {
  font-size: 13px;
  font-weight: 600;
  color: var(--tp-text-2);
  display: inline-block;
  margin-right: 30px;
}
.postbox__meta span:last-child {
  margin-right: 0;
}
.postbox__meta span i {
  color: var(--tp-heading-secondary);
  margin-right: 3px;
}
.postbox__meta span:hover {
  color: var(--tp-heading-secondary);
}
.postbox__meta span:hover i {
  color: var(--tp-heading-secondary);
}
.postbox__text img {
  max-width: 100%;
}
.postbox__text p {
  margin-bottom: 28px;
}
.postbox__text-single p {
  margin-bottom: 15px;
}
.postbox__slider .slick-arrow {
  position: absolute;
  left: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  font-size: 30px;
  color: var(--tp-heading-secondary);
  height: 50px;
  width: 50px;
  text-align: center;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  line-height: 55px;
}
.postbox__slider .slick-arrow:hover {
  background-color: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}
.postbox__slider .slick-arrow.slick-next {
  left: auto;
  right: 50px;
}
@media (max-width: 767px) {
  .postbox__slider .slick-arrow.slick-next {
    right: 10px;
  }
}
@media (max-width: 767px) {
  .postbox__slider .slick-arrow {
    left: 10px;
  }
}
.postbox__comment ul li {
  margin-bottom: 10px;
  list-style: none;
}
.postbox__comment ul li.children {
  margin-left: 100px;
}
@media (max-width: 767px) {
  .postbox__comment ul li.children {
    margin-left: 0px;
  }
}
.postbox__comment-form {
  margin-bottom: 20px;
  padding: 40px 30px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.09);
  background: var(--tp-common-white);
}
.postbox__comment-form-title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 40px;
}
.postbox__comment-input {
  position: relative;
  margin-bottom: 20px;
}
.postbox__comment-input span {
  font-weight: 600;
  color: var(--tp-common-black);
  margin-bottom: 12px;
  display: block;
}
.postbox__comment-input input, .postbox__comment-input textarea {
  height: 55px;
  padding: 0 20px;
  width: 100%;
  font-size: 14px;
  color: var(--tp-common-black);
  outline: none;
  border: 1px solid transparent;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  background: #f7f7f7;
}
.postbox__comment-input textarea {
  height: 175px;
  resize: none;
  padding-top: 20px;
  padding-bottom: 20px;
}
.postbox__comment-title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}
.postbox__comment-box {
  padding: 30px;
  padding-right: 40px;
  padding-top: 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.postbox__comment-avater img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.postbox__comment-name {
  margin-bottom: 5px;
}
.postbox__comment-name h5 {
  font-size: 16px;
  line-height: 1;
  margin-bottom: 0;
}
.postbox__comment-name span {
  font-size: 14px;
  color: var(--tp-text-1);
}
@media (max-width: 767px) {
  .postbox__comment-text {
    margin-left: 0;
    margin-top: 15px;
  }
}
.postbox__comment-text p {
  font-size: 16px;
  color: var(--tp-text-11);
  margin-bottom: 15px;
}
.postbox__comment-reply {
  margin-top: 10px;
}
.postbox__comment-reply a {
  display: inline-block;
  color: var(--tp-theme-1);
  background: var(--tp-common-white);
  line-height: 22px;
  padding: 4px 10px;
  font-weight: 500;
  font-size: 14px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.postbox__comment-reply a:hover {
  color: var(--tp-common-white);
  background: var(--tp-theme-1);
}
.postbox__comment-agree {
  padding-left: 5px;
}
.postbox__comment-agree input {
  margin: 0;
  appearance: none;
  -moz-appearance: none;
  display: block;
  width: 14px;
  height: 14px;
  background: var(--tp-common-white);
  border: 1px solid #b9bac1;
  outline: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  flex: 0 0 auto;
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  -o-transform: translateY(-1px);
  transform: translateY(-1px);
}
.postbox__comment-agree input:checked {
  position: relative;
  background-color: var(--tp-theme-1);
  border-color: transparent;
}
.postbox__comment-agree input:checked::after {
  box-sizing: border-box;
  content: "\f00c";
  position: absolute;
  font-family: var(--tp-ff-fontawesome);
  font-size: 10px;
  color: var(--tp-common-white);
  top: 46%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.postbox__comment-agree input:hover {
  cursor: pointer;
}
.postbox__comment-agree label {
  padding-left: 8px;
  color: var(--tp-text-1);
  line-height: 1;
}
.postbox__comment-agree label a {
  color: var(--tp-common-black);
  font-weight: 600;
  padding-left: 4px;
}
.postbox__comment-agree label a:hover {
  color: var(--tp-theme-1);
}
.postbox__comment-agree label:hover {
  cursor: pointer;
}
.postbox__tag span {
  font-size: 16px;
  margin-bottom: 17px;
  color: var(--tp-common-black);
  margin-right: 10px;
}

.rc__post ul li:not(:last-child) {
  margin-bottom: 15px;
}
.rc__post-thumb {
  margin-right: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .rc__post-thumb {
    margin-right: 12px;
  }
}
.rc__post-thumb img {
  max-width: 90px;
  height: 90px;
  object-fit: cover;
}
.rc__post-title {
  margin-bottom: 6px;
  font-size: 17px;
  line-height: 1.7;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .rc__post-title {
    font-size: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rc__post-title {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .rc__post-title {
    font-size: 14px;
    line-height: 1.4;
  }
}
.rc__post-title a:hover {
  color: var(--tp-theme-1);
}
.rc__meta span {
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-border-2);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .rc__meta span {
    font-size: 14px;
  }
}

.sidebar__wrapper {
  padding: 60px 30px 50px 30px;
  background: var(--tp-common-white);
  box-shadow: 5px 15px 30px rgba(5, 13, 54, 0.05);
}
.sidebar__widget:last-child {
  margin-bottom: 0;
}
.sidebar__widget-title {
  display: inline-block;
  font-size: 20px;
}
.sidebar__widget ul li {
  list-style: none;
}
.sidebar__widget ul li:first-child {
  padding-top: 0;
}
.sidebar__widget ul li a {
  padding: 15px 0;
  color: var(--tp-heading-primary);
  display: block;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 16px;
  border-bottom: 1px solid #eaeaea;
}
.sidebar__widget ul li a:hover {
  color: var(--tp-theme-1);
}
.sidebar__widget ul li a span {
  float: right;
  color: var(--tp-border-2);
  font-weight: 500;
  font-family: "Gordita-500";
}
.sidebar__widget ul li ul {
  padding-left: 15px;
}
.sidebar__search {
  position: relative;
}
.sidebar__search input {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: var(--tp-grey-1);
  padding: 0 25px;
  text-transform: capitalize;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  border: 2px solid var(--tp-grey-1);
  outline: none;
  padding-top: 3px;
  padding-right: 80px;
}
.sidebar__search input::placeholder {
  color: var(--tp-heading-primary);
  font-size: 16px;
}
.sidebar__search button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 24px;
  color: #fff;
  line-height: 60px;
  -webkit-border-radius: 0 7px 7px 0;
  -moz-border-radius: 0 7px 7px 0;
  -o-border-radius: 0 7px 7px 0;
  -ms-border-radius: 0 7px 7px 0;
  border-radius: 0 7px 7px 0;
  background: var(--tp-border-2);
}
.sidebar__banner::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.sidebar__banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  background: var(--tp-common-white);
}
.sidebar__banner-content h4 {
  padding: 15px 20px;
  font-size: 24px;
  color: var(--tp-common-black);
  text-transform: uppercase;
  margin-bottom: 0;
}

.tagcloud a {
  background: #F7F8F9;
  color: var(--tp-heading-primary);
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  padding: 10px 23px;
  margin-bottom: 10px;
  margin-right: 5px;
  border-radius: 5px;
  border: 0;
}
.tagcloud a:hover {
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tagcloud a {
    padding: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tagcloud a {
    padding: 18px;
  }
}
@media (max-width: 767px) {
  .tagcloud a {
    padding: 14px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tagcloud-d a {
    padding: 14px;
  }
}

blockquote {
  background: var(--tp-grey-1);
  padding: 35px 50px;
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  blockquote {
    padding-left: 15px;
    padding-right: 15px;
  }
}
blockquote p {
  line-height: 1.5;
  font-size: 20px;
  color: #57565e;
  font-weight: 400;
}
blockquote cite {
  font-size: 18px;
  display: block;
  margin-top: 10px;
  color: #070337;
  font-style: inherit;
  font-weight: 600;
  position: relative;
}
blockquote cite::before {
  content: "";
  font-size: 28px;
  color: var(--tp-theme-1);
  padding-bottom: 0px;
  display: inline-block;
  background: var(--tp-theme-1);
  height: 2px;
  width: 40px;
  font-weight: 400;
  text-align: center;
  top: -4px;
  margin-right: 10px;
  position: relative;
}

/*----------------------------------------*/
/*  17. CONTACT CSS START
/*----------------------------------------*/
.location-item {
  background-color: #F4F7F6;
  padding: 40px 40px 30px 40px;
  border-radius: 10px;
  min-height: calc(100% - 30px);
}

.location-icon svg {
  height: 80px;
  width: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  font-size: 3px;
  display: inline-block;
  color: var(--tp-border-2);
}

.location-title {
  color: var(--tp-heading-primary);
  font-size: 22px;
  font-family: "Gordita-500";
  font-weight: 500;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .location-title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .location-title {
    font-size: 16px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .contact-wrapper {
    margin-right: 0;
  }
}

.contact-title {
  font-size: 36px;
  color: var(--tp-heading-primary);
}
@media (max-width: 767px) {
  .contact-title {
    font-size: 30px;
  }
}

.contact-form-input span {
  display: block;
  color: var(--tp-text-2);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}

.contact-form-input input, .contact-form-input textarea {
  width: 100%;
  height: 60px;
  line-height: 58px;
  padding: 0 18px;
  background: #F8FAFA;
  border-radius: 4px;
  border: 2px solid #F8FAFA;
  color: var(--tp-heading-primary);
  font-size: 16px;
  outline: 0;
}
.contact-form-input input::placeholder, .contact-form-input textarea::placeholder {
  color: var(--tp-heading-primary);
  font-size: 18px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .contact-form-input input::placeholder, .contact-form-input textarea::placeholder {
    font-size: 16px;
  }
}
.contact-form-input input:focus {
  outline: none;
  border-color: var(--tp-heading-secondary);
}
.contact-form-input textarea {
  height: 200px;
  padding: 25px 20px;
  line-height: 1.1;
  resize: none;
}
.contact-form-input textarea:focus-visible {
  border-color: var(--tp-heading-secondary);
}

.contact-bg img {
  max-width: inherit;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .contact-bg img {
    max-width: 100%;
  }
}

.map-wrapper iframe {
  width: 100%;
  height: 550px;
  margin-bottom: -10px;
}

/*----------------------------------------*/
/*  18. FAQ CSS START
/*----------------------------------------*/
.accordion-buttons {
  background-color: #F8FAFA;
  box-shadow: none;
  padding: 37px 40px;
  font-size: 22px;
  font-family: "Gordita-500";
  font-weight: 500;
  width: 100%;
  text-align: left;
  color: var(--tp-heading-primary);
}
@media (max-width: 767px) {
  .accordion-buttons {
    padding: 20px 30px;
    font-size: 14px;
  }
}

.accordion-items {
  margin-bottom: 40px;
}

.accordion-buttons {
  position: relative;
  transition: 0.3s;
}
.accordion-buttons::after {
  position: absolute;
  content: "\f077";
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 6 Pro";
  color: var(--tp-border-2);
  font-size: 17px;
  padding-left: 10px;
}
@media (max-width: 767px) {
  .accordion-buttons::after {
    font-size: 14px;
    right: 10px;
  }
}
.accordion-buttons.collapsed::after {
  content: "\f107";
}

.accordion-buttons:not(.collapsed) {
  text-decoration: underline;
  color: var(--tp-border-2);
}

.accordion-items .accordion-body {
  font-size: 17px;
  color: var(--tp-text-2);
  padding: 20px 40px 30px 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
@media (max-width: 767px) {
  .accordion-items .accordion-body {
    font-size: 14px;
  }
}

/*----------------------------------------*/
/*  19. CART CSS START
/*----------------------------------------*/
.table-content table {
  background: #ffffff;
  border-color: #eaedff;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}
.table-content table.table > :not(:first-child) {
  border-top: 0;
}

.table-content .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #eaedff;
}

.table-content .product-quantity {
  float: none;
}

.table-content table td.product-name {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.table-content table td.product-name a:hover {
  color: var(--tp-theme-1);
}

.table-content table td {
  border-top: medium none;
  padding: 20px 10px;
  vertical-align: middle;
  font-size: 16px;
}

.table-content table th,
.table-content table td {
  border-bottom: 1px solid #eaedff;
  border-right: 1px solid #eaedff;
}

.product-quantity input {
  color: #000;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #dcdcdc;
}

.table td,
.table th {
  border-top: 1px solid #eaedff;
}

.product-quantity > input {
  width: 80px;
  border-radius: 3px;
}

.table-content table td.product-subtotal {
  font-size: 16px;
}

.table-content table td .cart-plus-minus {
  float: none;
  margin: 0 auto;
}

.coupon-all {
  margin-top: 50px;
}

.coupon {
  float: left;
}

@media (max-width: 767px) {
  .coupon {
    float: none;
  }
}
#coupon_code {
  height: 58px;
  border: 2px solid #eaedff;
  padding: 0 15px;
  margin-right: 10px;
  border-radius: 8px;
}

@media (max-width: 767px) {
  #coupon_code {
    margin-bottom: 15px;
  }
}
.coupon2 {
  float: right;
}

@media (max-width: 767px) {
  .coupon2 {
    float: none;
    margin-top: 15px;
  }
}
.cart-page-total {
  padding-top: 50px;
}

.cart-page-total > h2 {
  font-size: 25px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid #eaedff;
}

.cart-page-total > ul > li {
  list-style: none;
  font-size: 15px;
  color: #6f7172;
  padding: 10px 30px;
  border-bottom: 1px solid #eaedff;
  font-weight: 400;
}

.cart-page-total ul > li > span {
  float: right;
}

.cart-page-total li:last-child {
  border-bottom: 0;
}

td.product-thumbnail img {
  width: 125px;
}

.cart-plus,
.cart-minus {
  width: 25px;
  height: 30px;
  border: 2px solid #eaedff;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
}
.cart-plus:hover,
.cart-minus:hover {
  background-color: var(--tp-heading-secondary);
  color: var(--tp-common-white);
  border: 2px solid var(--tp-heading-secondary);
}

.cart-input {
  height: 30px;
  width: 32px;
  text-align: center;
  font-size: 14px;
  border: none;
  border-top: 2px solid var(--tp-border-1);
  border-bottom: 2px solid var(--tp-border-1);
  display: inline-block;
  vertical-align: middle;
  margin: 0 -3px;
  padding-bottom: 0px;
}

/* Checkout */
.coupon-accordion h3 {
  background-color: #f6f6f6;
  border-top: 3px solid rgba(255, 102, 82, 0.1);
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 25px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  width: auto;
  font-family: "Gordita-500";
}

.coupon-accordion h3::before {
  content: "\f07b";
  left: 15px;
  top: 13px;
  position: absolute;
  color: #6f7172;
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
}

.coupon-accordion span {
  color: #6f7172;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 500;
}

.coupon-accordion span:hover, p.lost-password a:hover {
  color: var(--tp-heading-primary);
}

.coupon-content {
  border: 1px solid #eaedff;
  display: none;
  margin-bottom: 20px;
  padding: 30px;
}

.coupon-info p.coupon-text {
  margin-bottom: 15px;
}

.coupon-info p {
  margin-bottom: 0;
}

.coupon-info p.form-row-first label, .coupon-info p.form-row-last label {
  display: block;
  color: #6f7172;
}

.coupon-info p.form-row-first label span.required, .coupon-info p.form-row-last label span.required {
  color: red;
  font-weight: 700;
}

.coupon-info p.form-row-first input, .coupon-info p.form-row-last input {
  border: 1px solid #eaedff;
  height: 45px;
  margin: 0 0 14px;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.coupon-info p.form-row-first input:focus, .coupon-info p.form-row-last input:focus {
  border-color: var(--tp-heading-secondary);
}

.coupon-info p.form-row input[type=submit]:hover, p.checkout-coupon input[type=submit]:hover {
  background: #3e976c none repeat scroll 0 0;
}

.coupon-info p.form-row input[type=checkbox] {
  position: relative;
  top: 2px;
}

.form-row > label {
  margin-top: 15px;
  margin-left: 15px;
  color: #6f7172;
}

.buttons-cart input, .coupon input[type=submit], .buttons-cart a, .coupon-info p.form-row input[type=submit] {
  background: #252525 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

p.lost-password {
  margin-top: 15px;
}

p.lost-password a {
  color: #6f6f6f;
}

p.checkout-coupon input[type=text] {
  height: 45px;
  padding: 0 15px;
  width: 100%;
  border: 1px solid #eaedff;
  margin-bottom: 15px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
p.checkout-coupon input[type=text]:focus {
  border-color: var(--tp-heading-secondary);
}

.coupon-checkout-content {
  display: none;
}

.checkbox-form h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 26px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.country-select {
  margin-bottom: 30px;
  position: relative;
}

.country-select select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #eaedff;
  padding: 0 10px;
  height: 50px;
}

.country-select label, .checkout-form-list label {
  color: #6f7172;
  display: block;
  margin: 0 0 5px;
}

.country-select label span.required, .checkout-form-list label span.required {
  color: red;
}

.country-select .nice-select {
  border: 1px solid #eaedff;
  height: 45px;
  padding-left: 10px;
  width: 100%;
  color: #6f7172;
  margin-bottom: 20px;
}

.country-select .nice-select .list {
  width: 100%;
}

.checkout-form-list {
  margin-bottom: 30px;
}

.checkout-form-list label {
  color: #6f7172;
}

.checkout-form-list input[type=text], .checkout-form-list input[type=password], .checkout-form-list input[type=email] {
  background: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  height: 45px;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.checkout-form-list input[type=text]:focus, .checkout-form-list input[type=password]:focus, .checkout-form-list input[type=email]:focus {
  border-color: var(--tp-heading-secondary);
}

.checkout-form-list input[type=text]::-moz-placeholder,
.checkout-form-list input[type=password]::-moz-placeholder,
.checkout-form-list input[type=email]::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type=text]::placeholder,
.checkout-form-list input[type=password]::placeholder,
.checkout-form-list input[type=email]::placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type=checkbox] {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.create-acc label {
  color: #6f7172;
  display: inline-block;
}

.create-account {
  display: none;
}

.ship-different-title h3 label {
  display: inline-block;
  margin-right: 20px;
  color: #6f7172;
}

.order-notes textarea {
  border: 1px solid #eaedff;
  height: 120px;
  padding: 15px;
  width: 100%;
  outline: 0;
  resize: none;
}

.order-notes textarea:focus {
  border-color: var(--tp-heading-secondary);
}

.order-notes textarea::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.order-notes textarea::placeholder {
  color: #6f7172;
  opacity: 1;
}

#ship-box-info {
  display: none;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-default > .panel-heading {
  border-radius: 0;
}

.your-order {
  padding: 30px 40px 45px;
  border: 3px solid rgba(255, 102, 82, 0.1);
}

@media (max-width: 767px) {
  .your-order {
    padding: 15px;
  }
}
.your-order h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 30px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.your-order-table table {
  background: none;
  border: 0;
  width: 100%;
}

.your-order-table table th, .your-order-table table td {
  border-bottom: 1px solid #eaedff;
  border-right: medium none;
  color: #6f7172;
  font-size: 14px;
  padding: 15px 0;
  text-align: left;
}

@media (max-width: 767px) {
  .your-order-table table th, .your-order-table table td {
    padding-right: 10px;
  }
}
.your-order-table table th {
  border-top: medium none;
  color: #6f7172;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.panel-body > p {
  color: #222;
}

.your-order-table table .shipping ul li {
  list-style: none;
}

.your-order-table table .shipping ul li input {
  position: relative;
  top: 2px;
}

.your-order-table table .shipping ul li label {
  color: #6f7172;
}

.your-order-table table .shipping th {
  vertical-align: top;
}

.your-order-table table .order-total th {
  border-bottom: 0;
  font-size: 14px;
}

.your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order-table table tr.cart_item:hover {
  background: #F9F9F9;
}

.your-order-table table tr.order-total td span {
  color: var(--tp-heading-primary);
  font-size: 18px;
  font-weight: 500;
  font-family: "Gordita-500";
}

.payment-method {
  margin-top: 40px;
}
.payment-method .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.payment-method .accordion-item {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid var(--tp-border-1);
}
.payment-method .accordion-button {
  font-size: 16px;
  font-weight: 500;
  color: var(--tp-heading-primary);
  padding: 23px 0;
  border: none;
}
.payment-method .accordion-button:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.payment-method .accordion-button::after {
  position: absolute;
  content: "\f067";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: var(--tp-ff-fontawesome);
  font-size: 16px;
  font-weight: 400;
  margin-left: 0;
  background-image: none;
}
.payment-method .accordion-button:not(.collapsed) {
  color: var(--tp-heading-primary);
  background-color: var(--tp-common-white);
  box-shadow: none;
}
.payment-method .accordion-button:not(.collapsed)::after {
  content: "\f068";
}
.payment-method .accordion-body {
  padding: 8px 0;
  padding-bottom: 40px;
}
.payment-method .accordion-collapse {
  border: none;
}

.panel-title > a {
  display: block;
}

.order-button-payment input {
  background: #232323 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  margin: 0px 0 0;
  padding: 0;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.order-button-payment input:hover {
  background: #3e976c none repeat scroll 0 0;
}

.payment-method .btn-link {
  background: no-repeat;
  border: medium none;
  border-radius: 0;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 0;
  padding: 3px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}

.payment-method .card {
  background-color: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  margin-bottom: 10px;
}

.payment-method .accordion .card:first-of-type {
  border: 1px solid #eaedff;
}

.card-header:first-child {
  border-radius: 0;
}

.payment-method .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaedff;
}

.order-button-payment button {
  width: 100%;
}
#example {
  width: 100%;
  margin-top: 50px;
}
ul.list {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.list li {
  display: flex;
  padding: 15px;
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  background-color: #fff;
  border-left: 4px solid #fff;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
}
ul.list li .title {
  font-weight: bold;
  font-size: 18px;
  position: relative;
  z-index: 1;
  line-height: 24px;
}
ul.list li .subtitle {
  position: relative;
  z-index: 1;
  font-size: 14px;
  opacity: 0.7;
}

ul.list li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  transition: width 0.3s ease;
}
ul.list li:hover {
  color: #fff;
}
ul.list li:hover:after {
  width: 100%;
  transition: width 0.3s ease;
}
/* Shopping */
ul.list li.shopping:after {
  background: linear-gradient(102deg, #cc00c5 0%, #1061b7 100%);
}
ul.list li.shopping {
  border-left-color: #cc00c5;
}
/* Personal */
ul.list li.personal:after {
  background: linear-gradient(102deg, #00cc88 0%, #1096b7 100%);
}
ul.list li.personal {
  border-left-color: #00cc88;
}
/* Work */
ul.list li.work:after {
  background: linear-gradient(102deg, #fa5437 0%, #ed4618 100%);
}
ul.list li.work {
  border-left-color: #fa5437;
}

/*----------------------------------------*/
/*  20. LOG-IN CSS START
/*----------------------------------------*/
.basic-login {
  padding: 90px;
  border: 2px solid #eaedff;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .basic-login {
    padding: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .basic-login {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .basic-login {
    padding: 30px;
  }
}
.basic-login h3 {
  font-size: 30px;
}

.basic-login .tp-btn-2 {
  text-transform: uppercase;
}

.basic-login input {
  width: 100%;
  height: 60px;
  border: 2px solid #eaedff;
  color: #6f7172;
  padding: 0 20px;
  margin-bottom: 20px;
  outline: none;
}

.basic-login input:focus {
  border-color: var(--tp-heading-secondary);
}

.basic-login input::placeholder,
.basic-login input::-moz-placeholder {
  color: #6f7172;
}

.basic-login label {
  color: #222;
  display: block;
}

.basic-login label span {
  color: red;
}

.login-action input {
  width: inherit;
  height: auto;
}

.login-action label {
  display: inline-block;
  margin-left: 5px;
}

.or-divide {
  border-top: 2px solid #eaedff;
  margin: 40px 0;
  text-align: center;
  position: relative;
}

.or-divide span {
  position: absolute;
  left: 0;
  right: 0;
  top: -9px;
  background: #ffffff;
  display: inline-block;
  width: 40px;
  margin: auto;
  line-height: 1;
  font-weight: 500;
}

@media (max-width: 767px) {
  .forgot-login {
    float: none;
  }
}
.forgot-login a {
  color: var(--tp-common-black);
}

.forgot-login a:hover {
  color: var(--tp-theme-1);
}

@media (max-width: 767px) {
  .log-rem {
    float: none;
    margin-bottom: 10px;
    display: block;
  }
}
/*----------------------------------------*/
/*  21. FOOTER CSS START
/*----------------------------------------*/
.footer-bg .f-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.footer-widget__title {
  color: var(--tp-common-white);
}
.footer-widget ul li a {
  color: var(--tp-common-white);
  font-size: 16px;
  display: inline-block;
}
.footer-widget ul li a:hover {
  color: var(--tp-heading-secondary);
  padding-left: 3px;
}
.footer-widget ul li:not(last-child) {
  margin-bottom: 22px;
}
.footer-widget p {
  color: var(--tp-common-white);
  margin-bottom: 25px;
}
.footer-widget__social a {
  height: 48px;
  width: 48px;
  line-height: 40px;
  display: block;
  color: var(--tp-heading-secondary);
  background: var(--tp-common-white);
  text-align: center;
  border-radius: 50px;
  font-size: 20px;
  margin-right: 8px;
}
.footer-widget__social a:hover {
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
  display: block;
}
.footer-widget__f-newsletter form {
  position: relative;
}
.footer-widget__f-newsletter form span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  font-size: 16px;
  color: var(--tp-text-2);
}
.footer-widget__f-newsletter form input {
  width: 100%;
  border: none;
  padding: 17px 180px 17px 55px;
  font-size: 16px;
  border-radius: 50px;
  color: var(--tp-text-2);
}
.footer-widget__f-newsletter form input::placeholder {
  font-size: 16px;
  color: var(--tp-text-2);
}
@media (max-width: 767px) {
  .footer-widget__f-newsletter form input::placeholder {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .footer-widget__f-newsletter form input {
    padding: 17px 170px 17px 55px;
  }
}
.footer-widget__submit {
  position: absolute;
  top: 8px;
  right: 10px;
}

.footer-col-4 {
  margin-left: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .footer-col-4 {
    margin-left: 0;
  }
}

.footer-widget-white .footer-widget__title {
  color: var(--tp-heading-primary);
}
.footer-widget-white p {
  color: var(--tp-text-2);
}
.footer-widget-white ul li a {
  color: var(--tp-text-2);
}

.footer-widget .footer-widget-info-list ul li {
  margin-bottom: 18px;
}

.footer-widget-info-icon i {
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  background: var(--tp-common-white);
  color: var(--tp-heading-secondary);
  border-radius: 50px;
  display: block;
}
.footer-widget-info-icon:hover i {
  background: var(--tp-heading-secondary);
  color: var(--tp-common-white);
}

.f-copyright__text span {
  color: var(--tp-common-white);
  font-size: 16px;
}
.f-copyright__list ul li {
  margin-right: 40px;
}
.banner-bg{
  background-image: url(../img/banner/banner-01.jpg);
}
@media (max-width: 767px) {
  .f-copyright__list ul li {
    margin-right: 12px;
  }
}
.f-copyright__list ul li a {
  font-size: 16px;
  color: var(--tp-text-2);
}
.f-copyright__list ul li a:hover {
  color: var(--tp-heading-secondary);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .f-copyright__list ul li a {
    font-size: 14px;
  }
}
.f-copyright__list ul li:last-child {
  margin-right: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .f-copyright__list ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .f-copyright__list ul li {
    margin-right: 18px;
  }
}
.f-copyright__dom {
  font-size: 16px;
  color: var(--tp-heading-primary);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .f-copyright__dom {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .f-copyright__dom {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.f-bottom-list ul {
  justify-content: end;
}
@media (max-width: 767px) {
  .f-bottom-list ul {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.footer-widget-info .footer-widget-info-text label {
  color: var(--tp-text-2);
  font-size: 14px;
  display: block;
  line-height: 1.4;
}
.footer-widget-info .footer-widget-info-text a {
  font-size: 16px;
  font-family: "Gordita-500";
  color: var(--tp-heading-primary);
  display: inline-block;
}
.footer-widget-info .footer-widget-info-text a:hover {
  color: var(--tp-heading-secondary);
  padding-left: 0;
}

.f-border-bottom {
  border-bottom: 1px solid #E8EAED;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .footer-2-col-2 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .footer-2-col-4 {
    margin-left: 0;
  }
}
.footer-2-col-4 ul li a {
  color: var(--tp-heading-secondary);
}

.footer-3-col-2 {
  margin-left: 48px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .footer-3-col-2 {
    margin-left: 0;
  }
}

.footer-3-col-3 {
  margin-left: 42px;
}
@media (max-width: 767px) {
  .footer-3-col-3 {
    margin-left: 0;
  }
}

.footer-3-col-5 {
  margin-left: 50px;
}

.f-copyright__social-area i {
  height: 48px;
  width: 48px;
  text-align: center;
  line-height: 50px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin-left: 5px;
  color: var(--tp-common-white);
  font-size: 20px;
}
.f-copyright__social-area i:hover {
  background: var(--tp-heading-secondary);
}

.footer-3-col-5 {
  margin-left: 0;
}
.background-shape{
  background-image: url(../img/bg/shape-bg-1.png);
  background-color: var(--tp-grey-1);
  padding-bottom: 80px;
  padding-top: 110px;
}
.slick-initialized .slick-slide {
  display: block;
  padding: 0px 15px;
}
section.breadcrumb__area.include-bg.pt-150.pb-150.breadcrumb__overlay{
  background-image: url(../img/breadcrumb/breadcrumb-bg-1.jpg);
  background-size: cover;
  background-position: center;
  height: 500px;
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(95, 58, 252,0.2);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

