@use '../utils' as *;

/*----------------------------------------*/
/*  17. CONTACT CSS START
/*----------------------------------------*/

.location-item {
	background-color: #F4F7F6;
	padding: 40px 40px 30px 40px;
	border-radius: 10px;
	min-height: calc(100% - 30px);
    &:hover {
        & .location-icon {
            & i {
                background-color: var(--tp-heading-secondary);
            }
        }
    }
}
.location-icon {
    & i {
        height: 80px;
        width: 80px;
        line-height: 80px;
        text-align: center;
        border-radius: 50%;
        font-size: 35px;
        color: var(--tp-common-white);
        background-color: var(--tp-border-2);
        display: inline-block;
    }
}
.location-title {
    color: var(--tp-heading-primary);
    font-size: 22px;
    font-family: 'Gordita-500';
    font-weight: 500;
    display: inline-block;
    &:hover {
        color: var(--tp-heading-secondary);
    }
    @media #{$xl} {
        font-size: 18px;
    }
    @media #{$md,$xs} {
        font-size: 16px;
    }
}
.contact-wrapper {
    @media #{$lg,$md,$xs} {
        margin-right: 0;
    }
}
.contact-title {
    font-size: 36px;
    color: var(--tp-heading-primary);
    @media #{$xs} {
        font-size: 30px;
    }
}
.contact-form-input {
    & span {
        display: block;
        color: var(--tp-text-2);
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 8px;
    }
}
.contact-form-input {
    & input, & textarea {
        width: 100%;
        height: 60px;
        line-height: 58px;
        padding: 0 18px;
        background: #F8FAFA;
        border-radius: 4px;
        border: 2px solid #F8FAFA;
        color: var(--tp-heading-primary);
        font-size: 16px;
        outline: 0;
        &::placeholder {
            color: var(--tp-heading-primary);
            font-size: 18px;
            @media #{$lg,$xs} {
                font-size: 16px;
            }
        }
    }
    & input:focus {
        outline: none;
        border-color: var(--tp-heading-secondary);
    }
    & textarea{
        height: 200px;
        padding: 25px 20px;
        line-height: 1.1;
        resize: none;
        &:focus-visible {
            border-color: var(--tp-heading-secondary);
        }
    }
} 
.contact-bg {
    & img {
        max-width: inherit;
        @media #{$xxl,$xl,$lg,$md,$xs} {
            max-width:100%;
        }
    }
}
.map-wrapper {
    & iframe {
        width: 100%;
        height: 550px;
        margin-bottom: -10px;
    }
}