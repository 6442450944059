@use '../utils' as *;

.section-title {
    & p {
        margin-bottom: 0;
        line-height: 28px;
    }
}
.tp-sub-title {
    color: var(--tp-heading-secondary);
    font-size: 20px;
    display: block;
    font-family: 'Gordita-500';
}
.tp-section-title {
    font-size: 44px;
    line-height: 1.3;
    @media #{$xl,$lg} {
        font-size: 36px;
    }
    @media #{$md} {
        font-size: 24px;
    }
    @media #{$xs} {
        font-size: 30px;
    }
    & br {
        @media #{$md, $xs} {
            display: none;
        }
    }
}
.tp-sub-title-box {
    padding: 12px 20px;
    font-size: 16px;
    background: rgb(255, 102, 82, .1);
    color: var(--tp-heading-secondary);
    border-radius: 5px;
    display: inline-block;
    font-family: 'Gordita-500';
    line-height: 1;
}
.hero-title-black {
    color: var(--tp-heading-b-light);
    font-size: 68px;
    line-height: 1.3;
    @media #{$xl} {
        font-size: 64px;
    }
    @media #{$lg} {
        font-size: 54px;
    }
    @media #{$md} {
        font-size: 50px;
    }
    & br {
        @media #{$xl,lg,$md} {
            display: none;
        }
    }
}
.tp-bline-stitle {
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    font-family: 'Gordita-500';
    font-size: 18px;
    color: var(--tp-heading-secondary);
    line-height: 1;
    &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: var(--tp-heading-secondary);
    }
}