@use '../utils' as *;

/*----------------------------------------*/
/*  15. MEANMENU CSS START
/*----------------------------------------*/



/* mean menu customize */
.mean-container a.meanmenu-reveal {
	display: none;
}
.mean-container .mean-nav {
	background: none;
	margin-top: 0;
}
.mean-container .mean-bar {
	padding: 0;
	min-height: auto;
	background: none;
}
.mean-container .mean-nav > ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
	display: block !important;
}
.mean-container a.meanmenu-reveal {
	display: none !important;
}
.mean-container .mean-nav ul li a {
	width: 100%;
	padding: 10px 0;
	color: var(--clr-common-black);
	border-top: 1px solid #ebebeb;
	font-size: 14px;
    line-height: 1.5;
    font-weight: 700;
    &:hover{
        color: var(--clr-theme-1);
    }
}


.mean-container .mean-nav ul li a.mean-expand {
	margin-top: 5px;
	padding: 0 !important;
	line-height: 14px;
	border: 1px solid #ebebeb !important;
	height: 30px;
	width: 30px;
    line-height: 30px;
	color: var(--clr-common-black);
	line-height: 30px;
	top: 0;
	font-weight: 400;
    &:hover{
        background: var(--clr-theme-1);
        color: var(--clr-common-white);
        border-color: var(--clr-theme-1);
    }
}

.mean-container .mean-nav ul li > a > i {
    display: none;
}
.mean-container .mean-nav ul li > a.mean-expand i{
    display: inline-block;
}

.mean-container .mean-nav > ul > li:first-child > a{
	border-top: 0;
}



.mean-container .mean-nav ul li a.mean-expand.mean-clicked{
    color: var(--clr-common-black);
}
.mean-container .mean-nav ul li a.mean-expand.mean-clicked i{
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    color: var(--clr-common-black);
}
